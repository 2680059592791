import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, Injectable, Inject, TemplateRef, importProvidersFrom } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AppComponent } from './app.component';
import { ApiService } from './api.service';
import { ApplicationStateService } from './application-state.service';
import { MapComponent } from './map/map.component';
import { MapWrapperComponent } from './map-wrapper/map-wrapper.component';
import { PlaceComponent } from './place/place.component';
import { PersonComponent } from './person/person.component';
import { PageComponent } from './page/page.component';
import { MenuComponent } from './menu/menu.component';
import { TooltipModule } from "./tooltip/tooltip.module";
import { HighlightModule } from "./highlight/highlight.module";
import { GALLERY_CONFIG, GalleryConfig, GalleryModule } from 'ng-gallery'
import { LIGHTBOX_CONFIG, LightboxConfig, LightboxModule } from 'ng-gallery/lightbox';
import * as Sentry from "@sentry/browser";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  MatomoModule,
  MatomoRouterModule
} from 'ngx-matomo-client';

// Sentry.init({
//   dsn: "https://3c316239b7c144b0b00c131b9b777e62@sentry.dadaserver.com/13"
// });

import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";
import { PersonListComponent } from './person-list/person-list.component';
import { KeyboardDirective } from './keyboard.directive';

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}
//   handleError(error) {
//     const eventId = Sentry.captureException(error.originalError || error);
//     // Sentry.showReportDialog({ eventId });
//   }
// }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


// @Pipe({
//   name: 'safeHtml',
//   standalone: true
// })
// export class SafeHtmlPipe implements PipeTransform  {
//   constructor(@Inject(String) private value, private sanitizer: DomSanitizer) {}
//   transform(value) {
//     return this.sanitizer.bypassSecurityTrustHtml(value);
//   }
// }

TemplateRef 

@NgModule({
  declarations: [
    AppComponent,
    MapWrapperComponent,
    MapComponent,
    PlaceComponent,
    PersonComponent,
    PageComponent,
    MenuComponent,
    // SafeHtmlPipe,
    PersonListComponent,
    KeyboardDirective
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserModule,
    GalleryModule,
    LightboxModule,
    BrowserAnimationsModule,
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatomoModule.forRoot(
      { trackerUrl: 'https://tracking.fox.co.at/', siteId: '33' }
    ),
    MatomoRouterModule,
    TooltipModule,
    HighlightModule
  ],
  // exports: [ TranslateModule ],
  providers: [ApiService, ApplicationStateService,
    provideAnimations(),
    importProvidersFrom(GalleryModule),
    importProvidersFrom(LightboxModule),
    // {
    // provide: GALLERY_CONFIG,
    // useValue: {
    //   autoHeight: true,
    //   imageSize: 'cover'
    // } as GalleryConfig }, 
    // {
    //   provide: LIGHTBOX_CONFIG,
    //   useValue: {
    //     keyboardShortcuts: true,
    //     exitAnimationTime: 500
    //   } as LightboxConfig
    // },
    { provide: ErrorHandler}],//, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 120]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        console.log("doing this");
        setTimeout(() => {
          console.log('e.anchor', e.anchor)
          viewportScroller.scrollToAnchor(e.anchor);
        })
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
// export class AppModule { }
}