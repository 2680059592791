import { Directive } from '@angular/core';

@Directive({
  selector: '[appKeyboard]'
})
export class KeyboardDirective {

  constructor() { }

}
