import { AfterViewInit, Component, Input, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, take, skipWhile } from 'rxjs/operators';
import { MapInfoWindow, MapMarker, GoogleMap, MapHeatmapLayer, GoogleMapsModule, MapAdvancedMarker } from '@angular/google-maps'
import { ApplicationStateService } from '../application-state.service';
import { FormGroup, FormControl } from '@angular/forms';

import {Md5} from 'ts-md5/dist/md5';

import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { MapStyle } from './map.style';
import { ApiService } from '../api.service';

// const md5 = new Md5();

const MAX_ZOOM = 24;
const MIN_ZOOM = 2;

function tile2long(x,z) {
  return (x/Math.pow(2,z)*360-180);
 }
 function tile2lat(y,z) {
  var n=Math.PI-2*Math.PI*y/Math.pow(2,z);
  return (180/Math.PI*Math.atan(0.5*(Math.exp(n)-Math.exp(-n))));
 }

const componentToHex = (c) => {
  const hex = parseInt(c).toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

 export interface HeatMapData {
   lat: number;
   lng: number;
   weight: number;
 }

 const residenceHeatmapGradient = [
   'rgba(65, 105, 225, 0)',
   'rgba(0, 56, 159, 1)',
   'rgba(0, 101, 202, 1)',
   'rgba(0, 143, 209, 1)',
   'rgba(0, 184, 177, 1)',
   'rgba(0, 223, 118, 1)',
   'rgba(94, 225, 0, 1)',
   'rgba(126, 242, 0, 1)',
   'rgba(175, 207, 0, 1)',
   'rgba(211, 157, 0, 1)',
   'rgba(227, 47, 0, 1)',
   'rgba(224, 0, 0, 1)',
 ];
 
 const deportationHeatmapGradient = [
   'rgba(45, 41, 86, 0)',
   'rgba(45, 41, 86, 1)',
   'rgba(68, 42, 100, 1)',
   'rgba(68, 42, 100, 1)',
   'rgba(96, 39, 109, 1)',
   'rgba(125, 30, 112, 1)',
   'rgba(155, 4, 110, 1)',
   'rgba(183, 0, 102, 1)',
   'rgba(209, 0, 88, 1)',
   'rgba(230, 0, 69, 1)',
   'rgba(246, 0, 45, 1)',
   'rgba(255, 0, 0, 1)',
 ];
 
 // https://colordesigner.io/gradient-generator/?mode=lch#33FF00-564C29
 const emigrationHeatmapGradient = [
   'rgba(86, 76, 41, 0)',
   'rgba(86, 76, 41, 1)',
   'rgba(100, 92, 36, 1)',
   'rgba(111, 109, 29, 1)',
   'rgba(120, 127, 19, 1)',
   'rgba(125, 147, 2, 1)',
   'rgba(126, 167, 0, 1)',
   'rgba(123, 188, 0, 1)',
   'rgba(114, 210, 0, 1)',
   'rgba(114, 210, 0, 1)',
   'rgba(94, 232, 0, 1)',
   'rgba(51, 255, 0, 1)',
 ];

 const eventTypeColor = {
  fine: {
    residence: {
        true: '#000000',
        false: '#7a7979'
      },
    emigration: {
        true: '#53A800',
        false: '#76a843'
      },
    deportation: {
        true: '#ad0707',
        false: '#ad3b3b',
    }
  },
  coarse: {
    residence: '#000',
    emigration: '#53A800',
    deportation: '#ad0707'
  }
 }

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
})


export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap
  @ViewChild(MapHeatmapLayer, { static: false }) public residenceHeatmap: MapHeatmapLayer;
  @ViewChild(MapHeatmapLayer, { static: false }) public emigrationHeatmap: MapHeatmapLayer;
  @ViewChild(MapHeatmapLayer, { static: false }) public deportationHeatmap: MapHeatmapLayer;

  @Input() mainMap = true;
  @Input() width = '100%';
  @Input() height = '100%';
  // @Input() events: any;
  @Input() set events (events: any) {
    let eventsMd5 = String(Md5.hashStr(JSON.stringify(events || [])));
    if ( events != undefined ) {
      this.events_ = events;
      if ( eventsMd5 != this.eventsMd5 ) {
        this.eventsHandled = false;
        this.eventsMd5 = eventsMd5;
      }
      if ( !this.eventsHandled && this.apiService.googleMapsApiLoaded_ && this.map !== undefined && this.map.googleMap !== undefined ) {
        // setTimeout(() => {this.handleMapEvents();}, 500);
        // this.handleMapEvents();
        google.maps.event.addListenerOnce(this.map.googleMap, "idle", () => {
          this.handleMapEvents();
        });
      }
    }
  };

  consentGiven = false;
  events_: any = [];
  eventsMd5 = "";
  eventsHandled = false;
  apiLoaded = false;
  rememberConsent = false;
  previous_zoom_level = 6;
  mapOptions: google.maps.MapOptions = {
      fullscreenControl: false,
      // styles: MapStyle,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
  }
  public center = { lat: 50.3061856, lng: 12.3007083 };
  public zoom = this.previous_zoom_level;

  mapCurrentZoom = this.previous_zoom_level;
  updateOnMapChange: boolean = true;
  data_initialisation = true;
  autocomplete: any;
  mapDataSubscription: any;
  autoCompleteInitCalled = false;
  // languageCode = 'en';
  mapUITranslations: any;
  searchParametersChanged: any;

  // GOOGLE_MAPS_MAP_ID = '1bee26ba1c783316'; // Vector
  GOOGLE_MAPS_MAP_ID = 'bbc1b8bfd380ce09'; // Raster

  uiTextElementsSubsriptionthis: any;
  cooperationParnerSubscription: any;
  programmaticallySetMapFocusSubscription: any;
  getFitToBoundsSubscription: any;
  consentGivenSubscription: any;
  searchParametersChangedSubscription: any;
  uiTextElementsSubsription: any;

  AdvancedMarkerElement:any;

  mapInitializeCounter = -1;


  // Map: google.maps.Map;
  // HeatMap: google.maps.visualization.MapHeatmapLayer;


  // heatmapData: any[] = [];
  // heatmapData$;

  // markerOptions: google.maps.MarkerOptions = {draggable: false};
  markers = [];



  residenceHeatmapOptions = {radius: 50, opacity: 0.55, dissipating: true, gradient:  residenceHeatmapGradient, maxIntensity: undefined};

  emigrationHeatmapOptions = {radius: 50, opacity: 0.55, dissipating: true, gradient: emigrationHeatmapGradient, maxIntensity: undefined};

  deportationHeatmapOptions = {radius: 50, opacity: 0.55, dissipating: true, gradient: deportationHeatmapGradient, maxIntensity: undefined};

  residenceHeatmapData: any[] = [];
  emigrationHeatmapData: any[] = [];
  deportationHeatmapData: any[] = [];

  zoomToLocationControlHidden: boolean = true;

  public changeMapZoom(direction: number): void {
    let zoom = this.map.getZoom();
    this.zoom = zoom + direction * 1;
  } 

  lastMapFocusProgrammaticChange = 0;
  lastMapFocusUserChange = 0;


  

  constructor(private httpClient: HttpClient, public apiService: ApiService, public applicationState: ApplicationStateService, location: Location, @Inject(DOCUMENT) private document: Document) {
    // get the current path to determine if it is a url provided through a cooperationpartners iframe
    // this.setMapData();

    // this.apiService.languageCode.subscribe(
    //   data => {
    //     console.log(this.languageCode, data)
    //     if (data != this.languageCode){
    //       this.languageCode = data;
    //       console.log("doing this", data)
    //       this.apiLoaded = of(false);
    //       delete this.map;
    //       delete this.heatmap;
    //       var scripts = document.getElementsByTagName("script");
    //       for (var i = 0; i < scripts.length; i++) {
    //         if (scripts[i].src.startsWith('https://maps.googleapis.com/')) {
    //           scripts[i].remove()
    //         }
    //       }
    //       var stylesheets = document.getElementsByTagName("link");
    //       for (var i = 0; i < stylesheets.length; i++) {
    //         if (stylesheets[i].href.startsWith('https://fonts.googleapis.com/')) {
    //           stylesheets[i].remove()
    //         }
    //       }
    //       this.initGoogleMap();
    //     }
    //     else 
    //     {
    //       this.languageCode = data;
    //     }
    //   }
    // );
    


    
    

  }

  public ngOnDestroy() {
    console.log("Map destroy called");

    if (this.searchParametersChangedSubscription) {
      this.searchParametersChangedSubscription.unsubscribe();
    }
    if (this.getFitToBoundsSubscription) {
      this.getFitToBoundsSubscription.unsubscribe();
    }
    if (this.cooperationParnerSubscription) {
      this.cooperationParnerSubscription.unsubscribe();
    }
    if (this.programmaticallySetMapFocusSubscription) {
      this.programmaticallySetMapFocusSubscription.unsubscribe();
    }
    if (this.uiTextElementsSubsription) {
      this.uiTextElementsSubsription.unsubscribe();
    }
    if (this.mapDataSubscription) {
      this.mapDataSubscription.unsubscribe();
    }
    delete this.map;
    delete this.residenceHeatmap;
    delete this.emigrationHeatmap;
    delete this.deportationHeatmap;
  }

  ngOnInit(): void {



    if ( this.mainMap ) {
      this.programmaticallySetMapFocusSubscription = this.apiService.programmaticallySetMapFocus.subscribe(
          (data) => {
            if ( data.hasOwnProperty('type') ) {
              if ( data.type == 'center' ) {
                const current = new Date().getTime();
                if ( current - this.lastMapFocusUserChange > 1000 ) {
                  this.zoom = data.values.zoom;
                  if ( data.values.hasOwnProperty('lat') ) {
                    this.center = { lat: data.values.lat, lng: data.values.lon };
                  }
                }
              }
              else if ( data.type == 'viewport' ) {
                // console.log("received bounds", data)
                // var bounds = new google.maps.LatLngBounds();
                // bounds.extend({lat: data.values.Zh.lo, lng:data.values.Jh.lo});
                // bounds.extend({lat: data.values.Zh.hi, lng:data.values.Jh.hi});
                this.map.fitBounds({east:data.values.Jh.hi, west: data.values.Jh.lo, north: data.values.Zh.lo, south: data.values.Zh.hi});
              }
            }
        }
      );
      this.searchParametersChangedSubscription = this.apiService.searchParametersChanged.subscribe( searchChanged => {
          // console.log('map.compontents.ts searchChanged', searchChanged);
          if ( searchChanged == true ) {
              this.updateMap(null, true);
          }
      });

    }

    



    var heatmapData = [];
    var markersTemp = [];
  }

  createHeatmapData(data:any): any[] {
    let heatmapData = [];
    data?.forEach(//buckets?.heatmap?.buckets?
      (element: any) => {
          // if ( !gridDataByRadius.hasOwnProperty(element.key) ) { gridDataByRadius[element.key] = []; }
          element?.fuzziness?.buckets?.forEach(
            fuzzinessElem => {
              var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
              if ( fuzzinessElem.key === '0' || fuzzinessElem.key === 'None' ) {
                heatmapData.push({location: location, weight: fuzzinessElem.doc_count});
              }
              else {
                let adjustedWeight = fuzzinessElem.doc_count / (element.doc_count - fuzzinessElem.doc_count + 1);
                heatmapData.push({location: location, weight: adjustedWeight});
              }
            }
          )
      });
      return heatmapData;
  }

  createCoarseGridData(data:any, zoomlevel:number, eventType='residence'): any[] {
    let gridData = [];
    data?.forEach(
      element => {
          var doc_count = element.doc_count;
          if ( doc_count > 0 ) {
            const docCount = doc_count.toString();
            const docCountNextGreater = 10**docCount.length;
            let paddingSize = 0;
            if ( doc_count > 10 ) {
              paddingSize = (1 - (docCountNextGreater - docCount) / docCountNextGreater) / 2 + docCount.length / 10;
            }
            // console.log(scale)
            const htmlMarker = document.createElement('div');
            const markerType = element.places.buckets.length == 1 ? 'place' : 'cluster';
            htmlMarker.className = `map-marker ${eventType} ${markerType}`
            htmlMarker.innerHTML = `<span style="padding: ${paddingSize}ch">${docCount}</span>`;
            htmlMarker.attributes['zoom'] = this.zoom;
            htmlMarker.attributes['type'] = 'cluster';
            var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
            gridData.push(
               {position: location, zoom: this.zoom, content: htmlMarker, title: `${docCount}`, type: markerType, eventType}
            );
          }
      });
      return gridData;
  }

  createFineGridAndHeatmapData(data:any, zoomlevel:number, eventType='residence'): any {
    let gridData = [];
    let heatmapData = [];
    // let initialMaxCount = 0;
    // let strokeColors = eventTypeColor.fine[eventType];
    data?.forEach(
      element => {
        // if (initialMaxCount == 0) {
        //   initialMaxCount = element.doc_count;
        // }
        // // console.log(element);
        // // var tile = element.key.split('/');
        // // tile = tile.map(x => parseInt(x));
        // // var lat = tile2lat(tile[2],tile[0]);
        // // var lng = tile2long(tile[1],tile[0]);
        // // var location = new google.maps.LatLng(lat, lng);
        // var max_size;
        // if ( initialMaxCount < 100 ) {
        //   max_size = 0.9;
        // }
        // else if ( initialMaxCount < 100 ) {
        //   max_size = 1.1;
        // }
        // else if ( initialMaxCount < 1000 ) {
        //   max_size = 1.4;
        // }
        // else if ( initialMaxCount < 10000 ) {
        //   max_size = 1.8;
        // }
        // else if ( initialMaxCount < 100000 ) {
        //   max_size = 2.5;
        // }
        // else if ( initialMaxCount < 1000000 ) {
        //   max_size = 3;
        // }
        // 1/2 pi r^2 = A
        // => sqrt (2 A / pi)
        
        var doc_count = element.doc_count;
          if ( doc_count > 0 ) {
            // var scale = max_size * Math.sqrt(2 * (doc_count / initialMaxCount) / 3.14);
            // if (scale < 0.7) {
            //   scale = 0.7;
            // }
            // var iconSize = 15 * scale;
            // var iconAnchor = iconSize / 2;
            // let icon = {
            //   url: '/assets/markers/residence_marker_current.png',
            //   scaledSize: new google.maps.Size(iconSize, iconSize),
            //   anchor: new google.maps.Point(iconAnchor, iconAnchor),
            // };
            // if ( element?.current?.buckets?.true?.doc_count == 0 ) { icon.url = '/assets/markers/residence_marker_other.png'; }
            // console.log(scale)
            // const svgMarker = {
            //   path: "M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0",
            //   fillColor: "#FFF",
            //   fillOpacity: 0.9,
            //   strokeWeight: 5,
            //   strokeColor,
            //   rotation: 0,
            //   scale: scale,
            //   anchor: new google.maps.Point(0, 0),
            // };

            
            
            var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
            // gridData.push({position: location, icon, type: 'place:'+ eventType, zoom: zoomlevel});
            element?.current?.buckets?.true?.fuzziness?.buckets?.forEach(
              fuzzinessElem => {
                var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
                if ( fuzzinessElem.key === '0' || fuzzinessElem.key === 'None' ) {
                  heatmapData.push({location: location, weight: fuzzinessElem.doc_count});
                }
                else {
                  let adjustedWeight = fuzzinessElem.doc_count / (element.doc_count - fuzzinessElem.doc_count + 1);
                  heatmapData.push({location: location, weight: adjustedWeight});
                }
              });

            const docCount = doc_count.toString();
            const docCountNextGreater = 10**docCount.length;
            let paddingSize = 0;
            if ( doc_count > 10 ) {
              paddingSize = (1 - (docCountNextGreater - docCount) / docCountNextGreater) / 2 + docCount.length / 4;
            }
            // console.log(scale)
            const htmlMarker = document.createElement('div');
            let notCurrent = "";
            if ( element?.current?.buckets?.true?.doc_count == 0 ) { notCurrent = " not-current"}
            htmlMarker.className = `map-marker ${eventType} close-up place` + notCurrent;
            htmlMarker.innerHTML = `<span style="padding: ${paddingSize}ch"></span>`;
            htmlMarker.attributes['zoom'] = this.zoom;
            htmlMarker.attributes['type'] = 'cluster';
            var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
            gridData.push(
               { position: location, zoom: this.zoom,  content: htmlMarker, title: docCount, type: 'place', eventType }
            );
          }
      });
      return {
        gridData,
        heatmapData
      };
  }

  getMapBounds(): any {
    if ( this.map ) {
      return this.map.getBounds().toJSON();
    }
    return '-';
  }

  ngAfterViewInit(): void {
    this.apiService.googleMapsApiLoaded.subscribe(active => {
      if (active) {
        // console.log("googleMapsApiLoaded")
        google.maps.event.addListenerOnce(this.map?.googleMap, "idle", () => {
          // console.log("Events", this.events_, this.map);
          // if ( this.events_ )
          this.handleMapEvents();
        });
      }
    });
  }

  handleMapEvents(): void {
    var heatmapData = [];
    var markersTemp = [];
    // pipe(
    //      skipWhile(value => !value), // skip null values
    //      take(1)).

    if ( this.mainMap ) {
      this.mapDataSubscription = this.apiService.geoPositionData.subscribe(
            data => {
              // data = data;
              // console.log("getting here")
              // console.log(data);
              // console.log(data._value);

                let residenceHeatmapData = [];
                let emigrationHeatmapData = [];
                let deportationHeatmapData = [];
                markersTemp = [];
                var zoomlevel = this.map.getZoom();
                // console.log('heatmap doc_count:' , data.events.filter_events.places.filter_places.heatmap )
                // console.log(this.zoom)

                var residenceMaxCount = 0;
                var deportationMaxCount = 0;
                var emigrationMaxCount = 0;
                var maxIntensity = undefined;
                if ( data?.events?.viewport?.type?.buckets?.residence?.hasOwnProperty('current') || data?.events?.viewport?.type?.buckets?.deportation?.hasOwnProperty('current') || data?.events?.viewport?.type?.buckets?.emigration?.hasOwnProperty('current') ) {
                  // console.log(data)
                  emigrationHeatmapData = this.createHeatmapData(data?.events?.viewport?.type?.buckets?.emigration?.current?.buckets?.true?.heatmap?.buckets);
                  deportationHeatmapData = this.createHeatmapData(data?.events?.viewport?.type?.buckets?.deportation?.current?.buckets?.true?.heatmap?.buckets);
                  residenceHeatmapData = this.createHeatmapData(data?.events?.viewport?.type?.buckets?.residence?.current?.buckets?.true?.heatmap?.buckets);
                  
                  
                  markersTemp = [
                    ...this.createCoarseGridData(data?.events?.viewport?.type?.buckets?.residence?.current?.buckets?.true?.grid?.buckets, zoomlevel, 'residence'),
                    ...this.createCoarseGridData(data?.events?.viewport?.type?.buckets?.deportation?.current?.buckets?.true?.grid?.buckets, zoomlevel, 'deportation'),
                    ...this.createCoarseGridData(data?.events?.viewport?.type?.buckets?.emigration?.current?.buckets?.true?.grid?.buckets, zoomlevel, 'emigration'),
                  ];
                  
                  
                }
                else
                {
                  let emigrationData = this.createFineGridAndHeatmapData(data?.events?.viewport?.type?.buckets?.emigration?.heatmap?.buckets, zoomlevel, 'emigration');
                  let deportationData = this.createFineGridAndHeatmapData(data?.events?.viewport?.type?.buckets?.deportation?.heatmap?.buckets, zoomlevel, 'deportation');
                  let residenceData = this.createFineGridAndHeatmapData(data?.events?.viewport?.type?.buckets?.residence?.heatmap?.buckets, zoomlevel, 'residence');
                  emigrationHeatmapData = emigrationData.heatmapData;
                  deportationHeatmapData = deportationData.heatmapData;
                  residenceHeatmapData = residenceData.heatmapData;
                  maxIntensity = 50;
                  markersTemp = [
                    ...deportationData.gridData,
                    ...emigrationData.gridData,
                    ...residenceData.gridData,
                  ];
                  // var max_count = 0;
                  // data?.events?.viewport?.type?.buckets?.residence?.heatmap?.buckets?.forEach(
                  //   element => {
                  //       if (max_count == 0) {
                  //         max_count = element.doc_count;
                  //       }
                  //       // console.log(element.doc_count);
                  //       // var tile = element.key.split('/');
                  //       // tile = tile.map(x => parseInt(x));
                  //       // var lat = tile2lat(tile[2],tile[0]);
                  //       // var lng = tile2long(tile[1],tile[0]);
                  //       var max_size;
                  //       if ( max_count < 10 ) {
                  //         max_size = 1.5;
                  //       }
                  //       else if ( max_count < 100 ) {
                  //         max_size = 1.75;
                  //       }
                  //       else if ( max_count < 1000 ) {
                  //         max_size = 2;
                  //       }
                  //       else if ( max_count < 10000 ) {
                  //         max_size = 3;
                  //       }
                  //       else if ( max_count > 10000 ) {
                  //         max_size = 4;
                  //       }
                  //       var scale = max_size * Math.sqrt(2 * (element.doc_count / max_count) / 3.14);
                  //       if (scale < 1) {
                  //         scale = 1;
                  //       }
                  //       let strokeColor = "#000000";
                  //       if ( element?.current?.buckets?.true?.doc_count == 0 ) { strokeColor = "#7a7979"; }
                  //       // console.log(scale)
                  //        var svgMarkerPlace = {
                  //           path: "M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0",
                  //           fillColor: "#FFFFFF",
                  //           fillOpacity: 0.9,
                  //           strokeWeight: 5,
                  //           strokeColor,
                  //           rotation: 0,
                  //           scale: scale,
                  //           anchor: new google.maps.Point(0, 0),
                  //         };
                  //       // console.log(element);
                  //       var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
                  //       markersTemp.push({position: location, icon: svgMarkerPlace, type: 'place:residence', zoom: zoomlevel});
                  //       heatmapData.push({location: location, weight: element.doc_count});
                  //   }
                  // );
                }
                this.residenceHeatmapData = residenceHeatmapData;
                this.residenceHeatmapOptions = {
                  ...this.residenceHeatmapOptions,
                  maxIntensity
                }
                this.emigrationHeatmapData = emigrationHeatmapData;
                this.emigrationHeatmapOptions = {
                  ...this.emigrationHeatmapOptions,
                  maxIntensity
                }
                this.deportationHeatmapData = deportationHeatmapData;
                this.deportationHeatmapOptions = {
                  ...this.deportationHeatmapOptions,
                  maxIntensity
                }
                this.markers = markersTemp;
                this.apiService.setSearchInProgress(false);
            
          },
          error => console.error(error),
          () => {
              // console.log('observer got a complete notification (map)')

            }
        );



      // this.heatmapData = this.getHeatMapData();
      // this.setHeatMapData();


      // setTimeout(()=>this.apiLoaded.emit(this.Map));
          // this.Map.setMap(this.Map.gMap._googleMap);
      // this.apiService = ApiService;

    } else {
      if ( this.events_.length > 0 ) {
        let gridData = [];
        var bounds = new google.maps.LatLngBounds();
        const lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 4,
          fillColor: '#ffffff',
        };
        const dashedLineSymbol = {
          path: "M 0,-2 0,2",
          strokeOpacity: 1,
          scale: 3,
        };
        var style = getComputedStyle(document.body)
        this.events_.forEach((event:any) => {
            if ( !event.hasOwnProperty('map_timeline_place') || event?.map_timeline_place?.coordinates === null || event?.map_timeline_place?.coordinates === undefined ) { return; }
            const htmlMarker = document.createElement('div');
            let highlight = `bio-event-nr-${event.number}`;
            htmlMarker.className = `map-marker ${event.type_char.toLowerCase()} place ${highlight}`;
            htmlMarker.innerHTML = `<span>${event.number}</span>`;
            htmlMarker.attributes['zoom'] = this.zoom;
            htmlMarker.attributes['type'] = 'place';
            var location = new google.maps.LatLng(event.map_timeline_place.coordinates.lat, event.map_timeline_place.coordinates.lon);
            let colorRgb = style.getPropertyValue(`--mtl-aggregation-${event.type_char.toLowerCase()}-rgb`).split(', ');
            let color = rgbToHex(colorRgb[0],colorRgb[1],colorRgb[2]);
            let title = `${event.title}\n${event.map_timeline_place.address.place_name_long}`;
            gridData.push(
              { position: location, zoom: this.zoom,  content: htmlMarker, title, date: event.date, type: 'bio-event', eventType: event.type_char, highlight } // options: {optimized: false}
            );
            bounds.extend(location);
            if ( event.map_timeline_movement_place_from_data_basis == 1 ) {
              if ( event.map_timeline_movement_place_from !== null && event.map_timeline_movement_place_from.coordinates !== null ) {
                var coordinates_from = new google.maps.LatLng(event.map_timeline_movement_place_from.coordinates.lat, event.map_timeline_movement_place_from.coordinates.lon);
                bounds.extend(coordinates_from);
                const line = new google.maps.Polyline({
                  geodesic: true,
                  strokeColor: color,
                  // strokeOpacity: 1.0,
                  strokeWeight: 3,
                  path: [
                    coordinates_from,
                    location,
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: "95%",
                    },
                  ],
                  map: this.map.googleMap,
                });
                location = coordinates_from;
              }
            } else if ( event.map_timeline_movement_place_from_data_basis == 2 && event.map_timeline_fuzzy_date.length > 0 && gridData.length > 1 ) {
              if ( gridData[gridData.length - 2].eventType != "DEATH" ) {
                const line = new google.maps.Polyline({
                  geodesic: true,
                  strokeColor: color,
                  // strokeOpacity: 1.0,
                  strokeWeight: 0,
                  path: [
                    gridData[gridData.length - 2].position,
                    location,
                  ],
                  icons: [
                    {
                      icon: dashedLineSymbol,
                      offset: "0",
                      repeat: "20px",
                    },
                    {
                      icon: lineSymbol,
                      offset: "95%",
                    },
                  ],
                  map: this.map.googleMap,
                });
              }
            }
        });
        this.markers = gridData;
        this.map.fitBounds(bounds);
        google.maps.event.addListenerOnce(this.map.googleMap, "bounds_changed", function () {
          if ( this.getZoom() > 14 ) {
            this.setZoom(14);
          }
        });
        this.eventsHandled = true;
      }
    }
  }


  // ngOnInit(): void {
  //   this.setHeatMapData();
  //
  //   console.log("And her2e");
  // }

  // setHeatMapData(): void {
  //   this.heatmapData = this.getHeatMapData();
  //   console.log(this.heatmapData);
  //   console.log("im here");
  // }

 //  boundsChanged(event: this.map.googleMap.boundsChanged): void {
 //   console.log(this.map.googleMap.getBounds());
 // }



 click(event: google.maps.MapMouseEvent) {
    // console.log(event)
  }

  updateMap(event=null, searchUpdated=false) {
      if ( this.mainMap ) {

        // console.log(event);
          this.lastMapFocusUserChange = new Date().getTime();
          var bounds = this.map.getBounds().toJSON();
          var center = this.map.getCenter().toJSON();
          var zoom = this.map.getZoom();
          // var precission_heatmap = zoomlevel + 2;
          // if (precission_heatmap > 24) {
          //   precission_heatmap = 24;
          // }
          // var precission_grid = zoomlevel + 1;
          // if (precission_grid > 24) {
          //   precission_grid = 24;
          // }
          // if(this.previous_zoom_level == zoomlevel && this.previous_zoom_level <= 8) {
          //   // If the zoom level is smaller or equal to 8 we don't have to reload the data from the server
          //   return;
          // }
         //  else
         //  {
         // }
         this.setMapData(zoom, bounds.north, bounds.west, bounds.south, bounds.east, center.lat, center.lng)
          this.previous_zoom_level = zoom;
          this.data_initialisation = false;
     
         //  console.log(zoomlevel);
          // console.log(precission_heatmap, precission_grid);
          // console.log(bounds)
          // if ( zoomlevel > 10 ) {
            // console.log(this.heatmapOptions)
            // this.heatmapOptions.dissipating = false;
            // this.heatmap.heatmap.setOptions( this.heatmapOptions );
            // this.heatmap.dissipating = false;
            // this.heatmap.options('dissipating', false);
            // this.heatmap.options({'dissipating':false, 'radius':100});
          // }
          // else
          // {
          //   this.heatmapOptions.dissipating = true;
          //   this.heatmap.heatmap.setOptions( this.heatmapOptions );
            // this.heatmap.options('dissipating', true);
            // this.heatmap.setOptions(radius= 50);
          // }
          // console.log(this.heatmapOptions.dissipating);
      }
   }

   preventDefault(e=null): void {
     if(e != null) {
      e.preventDefault();
     }
   }


   markerClickEvent(center, zoom, markerType, eventType='residence', reference=null): void {
     if ( markerType == "cluster" ) {
       var zoomlevel = this.map.getZoom();
      //  if (zoomlevel > zoom){
      // }
      // else
      // {
      //   this.zoom = zoom + 1;
      // }
      this.zoom = zoomlevel + 1;
       this.center = center;
     }
     else if ( markerType == 'place' ) {
        this.apiService.searchAtCoordinates(center.toJSON(), eventType);
     }
   }

   setCenterToPositionOnNextZoomStep(center, zoom): void {
     this.zoom = zoom + 1;
     this.center = center;
   }

  setMapData(zoomlevel = 6, tl_lat = 66, tl_lon=-180, br_lat=-66, br_lon=180, lat=50.3, lon=12.3): void {
    // this.heatmapData = [
    //   {location: new google.maps.LatLng(50.3061856, 12.3007083), weight: 10},
    // ];
    // console.log(this.heatmapData);
    // console.log(JSON.stringify(this.googleMap));
    // console.log(JSON.stringify(this.map));
    this.mapInitializeCounter++;
    this.apiService.setMapBounds(zoomlevel, tl_lat, tl_lon, br_lat, br_lon, lat, lon, this.mapInitializeCounter===0);
    this.mapCurrentZoom = zoomlevel;
  }
}
