import { Component, HostListener, Renderer2, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
// import { ApplicationStateService } from './application-state.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterOutlet } from '@angular/router';

import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';

import { DOCUMENT } from '@angular/common';
import { MapComponent } from './map/map.component';
import { timeout } from 'rxjs/operators';

function splitDate(data, identifier) {
  let dates = ["", "", ""];
  if ( data.hasOwnProperty(identifier) ) {
    dates = [...data[identifier].split('-'), "", "", ""];
  }
  dates.length = 3;
  let result = {};
  result[identifier] = undefined;
  result[`${identifier}_year`] = dates[0];
  result[`${identifier}_month`] = dates[1];
  result[`${identifier}_day`] = dates[2];
  return result;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements AfterViewInit {
  @ViewChild(MapComponent , {static :false}) map : MapComponent;
  public appSearchDisplayConditions = ['search', 'advanced-search', 'results'];
  public cooperationCredits = false;
  public cooperationPartnerName = '';
  title = 'Mapping the Lives';
  searchIsHidden = false;
  resultCount = 0;
  languageCode = 'en';
  blurMap = false;
  tabularResults = false;
  embeddedView = false;
  pagesAreHidden = false;
  supportUsBannerHidden = false;
  resultsAtPlace = false;
  scrolledToAnchorOnInit = false
  mapOptionsExpanded: boolean = false;
  mapOptionsAggregationsExpanded: boolean = false;
  mapOptionsTimelineExpanded: boolean = false;
  page:any = {}
  personReference = '';
  supportUsBannerExpanded = false;
  supportUsBannerShown = false;
  initialized = false;
  resultsAvailable = false;
  googleMapsAutocomplete: any;

  fragmentSubscription: any;
  currentRoute = "";
  fragment = "";

  public Object = Object;

  public mapControlForm = new FormGroup({
    map_aggregations: new FormControl('residence'),
    map_date: new FormControl(null),
  });

  public lastViewedBiographies = [];
  public menuItems = [];
  public currentPage = "";

  public startYear = 1928;
  public endYear = 1946;
  public yearsRange = [];
  public cooperationPartnerData = {};
  public paypalDonationButton;

  public searchInProgress = false;

  public rememberConsent = false;
  public consentGivenGoogleMaps = false;
  public loadMap = false;

  currentYear = new Date().getFullYear();

  public slug = '';
  public uiTranslations;
  public lastDisplay;

  public rightOverlayRedirectToOnClose = '/';

  public rightOverlayFullscreen = false;
  public leftOverlayFullscreen = false;

  searchByQueryDataSubscription: any;
  searchByAddressDataSubscription: any;

  initialQueryParamsSubscription: any;

  searchByQueryResults = [];
  searchByQueryResultsCount = null;
  searchByQueryNextPage = null;

  searchByAddressResults = [];
  searchByAddressResultsCount = null;
  searchByAddressNextPage = null;

  activeSearchParametersFromAdvancedSearch = false;

  selectedIndex = 0;
  currentLetter = '';
  locationNotFound = false;
  mapUpdatesText = 'off';


  restrictToVisibleMap = false;

  public searchFilterHidden = true;
  labelPosition = 'before';
  sliderColor = '#00A9B0';
  public filterActive = false;

  public searchByQuery = false;
  public searchByAddress = false;

  public dateFormatSwitchers = {
    'residence': false,
    'birth': false,
    'death': false,
    'emigration': false,
    'imprisonment': false,
    'expulsion': false,
    'deportation': false,
  }

  ngAfterViewInit() {
    this.initialized = true;
  }


  onDigitInput(event){
    return;
    let element = null;
    console.error("element value", element, event, event.target.value);
    if (event.code !== 'Backspace') {
      if (event.target.value.length >= Number(event.target.attributes.getNamedItem('maxlength').value)) {
        element = event.srcElement.nextElementSibling;
      }
    } else if (event.code === 'Backspace') {
      if (event.target.value.length == 0) {
        element = event.srcElement.previousElementSibling;
      }
    }
    if(element === null)
    {
      if (event.target.value.length >= Number(event.target.attributes.getNamedItem('maxlength').value)) {
        return false;
      }
      return;
    }
    else
    {
        element.focus();
    }
  }


  mapCenter = { lat: 50.3061856, lng: 12.3007083 };
  mapZoom = 6;

  zoomToLocationControlHidden: boolean = true;

  activeMapControllOverlay: string = 'support-us';

  public setFilterByMap(value): void {
    this.restrictToVisibleMap = value;
    this.filterActive = this.restrictToVisibleMap;
    // this.searchForm.get('restrictToVisibleMap').setValue(this.restrictToVisibleMap)
  }

  public toggleFilterByMap(): void {
    this.setFilterByMap(!this.restrictToVisibleMap)
    this.onSubmit(false);
  }

  public getValueToDisplay(data): string {
    if ( data != undefined ) {
      if ( data.hasOwnProperty(this.languageCode) == true ) {
        return data[this.languageCode];
      }
      if ( data.hasOwnProperty("default") == true ) {
        return data.default;
      }
    }
    return "";
  }

  public clearSearchByAddress() {
    if ( this.apiService.searchByQuery.results.length == 0 ) {
      this.apiService.currentSearchDisplay = 'base-search';
    }
    this.apiService.refreshAddressSearch = true;
    this.apiService.router.navigate([], { relativeTo: this.apiService.route, queryParams: {coords: null}, queryParamsHandling: 'merge'})
  }

  public getResidenceValueToDisplay(data): string {
    // console.log('Residence value', data)
    if ( data != undefined ) {
      if ( data.hasOwnProperty('timeline_events') == true ) {
        let residence = undefined;
        for ( let timelineEvent of data.timeline_events ) {
          if ( timelineEvent.type_char == 'RESIDENCE' ) {
            if ( timelineEvent.default_on_map ) {
              residence = timelineEvent.place_name_list;
              break;
            }
            if ( residence == undefined ) {
              residence = timelineEvent.place_name_list;
            }
          }
        }
        if ( residence ) {
          return residence[this.languageCode];
        } 
      }
    }
    return " - ";
  }

  filterResultsFormClearButtonHidden = true;


  updateTimerFilter: any;
  updateIntervalFilter = 800;

  public filterResultsChanged(): void {
    clearTimeout(this.updateTimerFilter);
    this.updateTimerFilter = setTimeout(() => { this.onSubmitFilterResults(); }, this.updateIntervalFilter);
  }

  public filterResultsChangedAtAddress(): void {
    clearTimeout(this.updateTimerFilter);
    this.updateTimerFilter = setTimeout(() => { this.onSubmitFilterResultsAtAddress(); }, this.updateIntervalFilter);
  }



  public filterResultsForm = new FormGroup({
    filterResults: new FormControl('')});
    
  public searchForm = new FormGroup({
    filter_results: new FormControl(''),
    restrict_to_map_bounds: new FormControl(false),
    coordinates_show_all: new FormControl(false),
    forename: new FormControl(''),
    surname: new FormControl(''),
    maidenname: new FormControl(''),

    res_fd_day: new FormControl(''),
    res_fd_month: new FormControl(''),
    res_fd_year: new FormControl(''),
    res_fd_from_day: new FormControl(''),
    res_fd_from_month: new FormControl(''),
    res_fd_from_year: new FormControl(''),
    res_fd_to_day: new FormControl(''),
    res_fd_to_month: new FormControl(''),
    res_fd_to_year: new FormControl(''),
    res_single_fd: new FormControl(false),
    res_street: new FormControl(''),
    res_streetnr: new FormControl(''),
    res_community: new FormControl(''),
    res_quarter: new FormControl(''),
    res_district: new FormControl(''),
    res_state: new FormControl(''),
    res_country: new FormControl(''),
      
    birth_fd_day: new FormControl(''),
    birth_fd_month: new FormControl(''),
    birth_fd_year: new FormControl(''),
    birth_single_fd: new FormControl(false),
    birth_fd_from_day: new FormControl(''),
    birth_fd_from_month: new FormControl(''),
    birth_fd_from_year: new FormControl(''),
    birth_fd_to_day: new FormControl(''),
    birth_fd_to_month: new FormControl(''),
    birth_fd_to_year: new FormControl(''),
    birth_place: new FormControl(''),

    death_fd_day: new FormControl(''),
    death_fd_month: new FormControl(''),
    death_fd_year: new FormControl(''),
    death_single_fd: new FormControl(false),
    death_fd_from_day: new FormControl(''),
    death_fd_from_month: new FormControl(''),
    death_fd_from_year: new FormControl(''),
    death_fd_to_day: new FormControl(''),
    death_fd_to_month: new FormControl(''),
    death_fd_to_year: new FormControl(''),
    death_place: new FormControl(''),
    
    deportation_fd_day: new FormControl(''),
    deportation_fd_month: new FormControl(''),
    deportation_fd_year: new FormControl(''),
    deportation_single_fd: new FormControl(false),
    deportation_fd_from_day: new FormControl(''),
    deportation_fd_from_month: new FormControl(''),
    deportation_fd_from_year: new FormControl(''),
    deportation_fd_to_day: new FormControl(''),
    deportation_fd_to_month: new FormControl(''),
    deportation_fd_to_year: new FormControl(''),
    deportation_place: new FormControl(''),
    deportation_place_from: new FormControl(''),
    
    emigration_fd_day: new FormControl(''),
    emigration_fd_month: new FormControl(''),
    emigration_fd_year: new FormControl(''),
    emigration_single_fd: new FormControl(false),
    emigration_fd_from_day: new FormControl(''),
    emigration_fd_from_month: new FormControl(''),
    emigration_fd_from_year: new FormControl(''),
    emigration_fd_to_day: new FormControl(''),
    emigration_fd_to_month: new FormControl(''),
    emigration_fd_to_year: new FormControl(''),
    emigration_place: new FormControl(''),
    emigration_place_from: new FormControl(''),
    
    expulsion_fd_day: new FormControl(''),
    expulsion_fd_month: new FormControl(''),
    expulsion_fd_year: new FormControl(''),
    expulsion_single_fd: new FormControl(false),
    expulsion_fd_from_day: new FormControl(''),
    expulsion_fd_from_month: new FormControl(''),
    expulsion_fd_from_year: new FormControl(''),
    expulsion_fd_to_day: new FormControl(''),
    expulsion_fd_to_month: new FormControl(''),
    expulsion_fd_to_year: new FormControl(''),
    expulsion_place: new FormControl(''),
    
    imprisonment_fd_day: new FormControl(''),
    imprisonment_fd_month: new FormControl(''),
    imprisonment_fd_year: new FormControl(''),
    imprisonment_single_fd: new FormControl(false),
    imprisonment_fd_from_day: new FormControl(''),
    imprisonment_fd_from_month: new FormControl(''),
    imprisonment_fd_from_year: new FormControl(''),
    imprisonment_fd_to_day: new FormControl(''),
    imprisonment_fd_to_month: new FormControl(''),
    imprisonment_fd_to_year: new FormControl(''),
    imprisonment_place: new FormControl(''),
  });
  // public searchForm = new FormGroup({
  //   restrictToVisibleMap: new FormControl(false),
  //   forename: new FormControl(''),
  //   surname: new FormControl(''),
  //   maidenname: new FormControl(''),
  //   residence: new FormGroup({
  //     residence_date: new FormGroup({
  //       residence_date_day: new FormControl(''),
  //       residence_date_month: new FormControl(''),
  //       residence_date_year: new FormControl(''),
  //     }),
  //     residence_date_range: new FormControl(true),
  //     residence_date_from: new FormGroup({
  //       residence_date_from_day: new FormControl(''),
  //       residence_date_from_month: new FormControl(''),
  //       residence_date_from_year: new FormControl(''),
  //     }),
  //     residence_date_to: new FormGroup({
  //       residence_date_to_day: new FormControl(''),
  //       residence_date_to_month: new FormControl(''),
  //       residence_date_to_year: new FormControl(''),
  //     }),
  //     street_streetnumber: new FormGroup({
  //       street: new FormControl(''),
  //       streetnumber: new FormControl(''),
  //     }),
  //     community: new FormControl(''),
  //     quarter: new FormControl(''),
  //     district: new FormControl(''),
  //     state: new FormControl(''),
  //     country: new FormControl(''),
  //   }),
  //   birth_death: new FormGroup({
  //     birth: new FormGroup({
  //       birth_date: new FormGroup({
  //         birth_date_day: new FormControl(''),
  //         birth_date_month: new FormControl(''),
  //         birth_date_year: new FormControl(''),
  //       }),
  //       birth_date_range: new FormControl(true),
  //       birth_date_from: new FormGroup({
  //         birth_date_from_day: new FormControl(''),
  //         birth_date_from_month: new FormControl(''),
  //         birth_date_from_year: new FormControl(''),
  //       }),
  //       birth_date_to: new FormGroup({
  //         birth_date_to_day: new FormControl(''),
  //         birth_date_to_month: new FormControl(''),
  //         birth_date_to_year: new FormControl(''),
  //       }),
  //       birth_place: new FormControl(''),
  //     }),
  //     death: new FormGroup({
  //       death_date: new FormGroup({
  //         death_date_day: new FormControl(''),
  //         death_date_month: new FormControl(''),
  //         death_date_year: new FormControl(''),
  //       }),
  //       death_date_range: new FormControl(true),
  //       death_date_from: new FormGroup({
  //         death_date_from_day: new FormControl(''),
  //         death_date_from_month: new FormControl(''),
  //         death_date_from_year: new FormControl(''),
  //       }),
  //       death_date_to: new FormGroup({
  //         death_date_to_day: new FormControl(''),
  //         death_date_to_month: new FormControl(''),
  //         death_date_to_year: new FormControl(''),
  //       }),
  //       death_place: new FormControl(''),
  //     })
  //   }),
  //   deportation: new FormGroup({
  //     deportation_date: new FormGroup({
  //       deportation_date_day: new FormControl(''),
  //       deportation_date_month: new FormControl(''),
  //       deportation_date_year: new FormControl(''),
  //     }),
  //     deportation_date_range: new FormControl(true),
  //     deportation_date_from: new FormGroup({
  //       deportation_date_from_day: new FormControl(''),
  //       deportation_date_from_month: new FormControl(''),
  //       deportation_date_from_year: new FormControl(''),
  //     }),
  //     deportation_date_to: new FormGroup({
  //       deportation_date_to_day: new FormControl(''),
  //       deportation_date_to_month: new FormControl(''),
  //       deportation_date_to_year: new FormControl(''),
  //     }),
  //     deportation_place: new FormControl(''),
  //     deportation_place_from: new FormControl(''),
  //   }),
  //   emigration: new FormGroup({
  //     emigration_date: new FormGroup({
  //       emigration_date_day: new FormControl(''),
  //       emigration_date_month: new FormControl(''),
  //       emigration_date_year: new FormControl(''),
  //     }),
  //     emigration_date_range: new FormControl(true),
  //     emigration_date_from: new FormGroup({
  //       emigration_date_from_day: new FormControl(''),
  //       emigration_date_from_month: new FormControl(''),
  //       emigration_date_from_year: new FormControl(''),
  //     }),
  //     emigration_date_to: new FormGroup({
  //       emigration_date_to_day: new FormControl(''),
  //       emigration_date_to_month: new FormControl(''),
  //       emigration_date_to_year: new FormControl(''),
  //     }),

  //     emigration_place: new FormControl(''),
  //     emigration_place_from: new FormControl(''),
  //   }),
  //   expulsion: new FormGroup({
  //     expulsion_date: new FormGroup({
  //       expulsion_date_day: new FormControl(''),
  //       expulsion_date_month: new FormControl(''),
  //       expulsion_date_year: new FormControl(''),
  //     }),
  //     expulsion_date_range: new FormControl(true),
  //     expulsion_date_from: new FormGroup({
  //       expulsion_date_from_day: new FormControl(''),
  //       expulsion_date_from_month: new FormControl(''),
  //       expulsion_date_from_year: new FormControl(''),
  //     }),
  //     expulsion_date_to: new FormGroup({
  //       expulsion_date_to_day: new FormControl(''),
  //       expulsion_date_to_month: new FormControl(''),
  //       expulsion_date_to_year: new FormControl(''),
  //     }),
  //     expulsion_place: new FormControl(''),
  //   }),
  //   imprisonment: new FormGroup({
  //     imprisonment_date: new FormGroup({
  //       imprisonment_date_day: new FormControl(''),
  //       imprisonment_date_month: new FormControl(''),
  //       imprisonment_date_year: new FormControl(''),
  //     }),
  //     imprisonment_date_range: new FormControl(true),
  //     imprisonment_date_from: new FormGroup({
  //       imprisonment_date_from_day: new FormControl(''),
  //       imprisonment_date_from_month: new FormControl(''),
  //       imprisonment_date_from_year: new FormControl(''),
  //     }),
  //     imprisonment_date_to: new FormGroup({
  //       imprisonment_date_to_day: new FormControl(''),
  //       imprisonment_date_to_month: new FormControl(''),
  //       imprisonment_date_to_year: new FormControl(''),
  //     }),
  //     imprisonment_place: new FormControl(''),
  //   }),
  // });

  toggleMapControllOverlay(mapControll: string) {
    if ( this.activeMapControllOverlay == mapControll ) {
      this.activeMapControllOverlay = undefined;
    }
    else {
      this.activeMapControllOverlay = mapControll;
      if ( mapControll == "map-timeline" ) {
        this.scrollToMapDate( this.mapControlForm.value.map_date );
      }
    }
  }

  public locationForm = new FormGroup({ place: new FormControl('') });

  

  // @HostListener('window:resize', ['$event'])
    // public onResize(event: Event) {
    //   this.applicationState.setWindowSize();
    // }

  
  @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.apiService.setScreenSize();
    }

  scrollToMapDate(value: any) {
    if ( value != null ) {
      setTimeout( () => document.getElementById('map-year-' + value + '-label').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      }), 300);
    }
  }


  constructor(
    // public applicationState: ApplicationStateService,
      public apiService: ApiService,
      private route: ActivatedRoute,
      private router: Router,
      private renderer: Renderer2,
      translate: TranslateService,
      private changeDetector: ChangeDetectorRef,
      @Inject(DOCUMENT) private document: Document) {
        this.apiService.page.subscribe(
          data => {
            if ( data.hasOwnProperty('label') == true ) {
              this.page = data;
            }} );
        this.mapControlForm.controls.map_date.valueChanges.subscribe((value: any) => {
          this.scrollToMapDate(value);
          this.apiService.setMapDate(value);
        });
        translate.setDefaultLang('en');
        for (let i=this.startYear; i<this.endYear; i++) {
          this.yearsRange.push({
            value: `${i}-01-01`,
            label: `${i}`,
          });
        }
        this.apiService.languageCode.subscribe(
          data => {
            if ( data != null && this.languageCode != data ){
              this.languageCode = data;
              translate.use(this.languageCode);
              if(this.map && this.consentGivenGoogleMaps) {
                this.mapZoom = this.map.map.getZoom();
                let currentCenter = this.map.map.getCenter();
                this.mapCenter = {lat: currentCenter.lat(), lng:currentCenter.lng()};
                this.loadMap = false;
                this.changeDetector.detectChanges();
                this.loadMap = true;
              }
            }
          }
        );
        this.apiService.cooperationPartnerData.subscribe(
          data => {
            if ( data.hasOwnProperty('names') == true ) {
              this.cooperationCredits = data.show_cooperation_credit;
              this.cooperationPartnerName = data.names;
            } 
          }
        );

        this.apiService.lastViewedBiographies.subscribe( 
          data => {
            this.lastViewedBiographies = data;
          }
        );

        this.apiService.menuItems.subscribe(
          data => {
            if ( data.length > 0 ) {
              this.menuItems = data;
            }
          }
        );

        this.apiService.currentPage.subscribe( 
          data => {
            this.currentPage = data;
          }
        );

    this.apiService.consentGivenGoogleMaps.subscribe(
      value => {
        this.consentGivenGoogleMaps = value;
        this.loadMap = value;
    });

    this.apiService.cooperationPartnerData.subscribe(
      value => {
        this.cooperationPartnerData = value;
        if (value.hasOwnProperty('zoom')) {
          this.mapZoom = value.zoom;
          this.mapCenter = value.center;
        }
    });

    this.apiService.embeddedView.subscribe(
        value => {
          this.embeddedView = value;
    });



    // this.applicationState.currentDisplay.subscribe(
    //   value => {
    //     this.apiService.currentDisplay = value;
    // });

    // this.applicationState.lastDisplay.subscribe(
    //   value => {
    //     this.lastDisplay = value;
    // });


    

    // this.route.queryParams.subscribe(
    //   params => {
    //     console.log("application ", params);
    //     if ( params.cooperationpartner != undefined && params.cooperationpartner != this.apiService.cooperationpartner && params.cooperationpartner.length == 36) {
    //         this.apiService.setCooperationPartner(params.cooperationpartner);
    //     }
    //   });

    this.apiService.activeSearchParametersFromAdvancedSearch.subscribe(
      data => {
        this.activeSearchParametersFromAdvancedSearch = data;
        // console.log("receiving language choice "+ data)
      }
    );

    this.apiService.cooperationPartnerData.subscribe(
      data => {
        if( data.hasOwnProperty('filters') && Object.keys(data.filters).length > 0 ) {
          this.searchForm.patchValue(data.filters);
          this.dateFormatSwitchers = {
            residence: Boolean(data?.res_single_fd),
            birth: Boolean(data?.birth_single_fd),
            death: Boolean(data?.death_single_fd),
            deportation: Boolean(data?.deportation_single_fd),
            emigration: Boolean(data?.emigration_single_fd),
            expulsion: Boolean(data?.expulsion_single_fd),
            imprisonment: Boolean(data?.imprisonment_single_fd),
          };
          this.onSubmit()
        }
      }
    );

    // this.searchByQueryDataSubscription = this.apiService.searchByQuery.subscribe(
    //   data => {
    //     console.log('searchByQuery', data);
    //     this.searchByQuery = data;
    //     if ( data != null ) {
    //       if ( data.hasOwnProperty('results') ) {
    //         this.apiService.currentSearchDisplay = 'results';
    //         this.searchByQueryResults = [ ...data['results'] ];
    //         this.searchByQueryResultsCount = data['count'];
    //       }
    //       this.apiService.setSearchInProgress(false);
    //     } else {
    //       this.searchByQueryResults = [];
    //       this.searchByQueryResultsCount = null;
    //     }
    //   });

    // this.searchByAddressDataSubscription = this.apiService.searchByAddress.subscribe(
    //   obj => {
    //     if ( !obj.hasOwnProperty('data') ) { return; }
    //     let data = obj.data;
    //     console.log('searchByAddress', data);
    //     this.searchByAddress = data;
    //     if ( data != null ) {
    //       if ( data.hasOwnProperty('results') ) {
    //         this.searchByAddressResults = [ ...data['results'] ];
    //         this.searchByAddressResultsCount = data['count'];
    //         this.apiService.currentDisplay = 'search';
    //         this.apiService.currentSearchDisplay = 'results';
    //       }
    //       this.apiService.setSearchInProgress(false);
    //     } else {
    //       this.searchByAddressResults = [];
    //     }
    //   });

    this.apiService.mapUpdates.subscribe(
        value => {
          if (value) {
            this.mapUpdatesText = "off";
          }
          else
          {
            this.mapUpdatesText = "on";
          }
    });

    this.apiService.embeddedView.subscribe(
      data => {
        this.embeddedView = data;
      }
    );

    this.apiService.searchInProgress.subscribe(
      value => {
        this.searchInProgress = value;
    });
    this.apiService.searchByQueryNextPage.subscribe(
      data => {
        this.searchByQueryNextPage = data;
      }
    );
    this.initialQueryParamsSubscription = this.apiService.initialQueryParams.subscribe(
      (data) => {
        console.log('this.initialQueryParamsSubscription', data)
        if ( this.Object.keys(data).length > 0 ) {
          data = {
            ...data,
            ...splitDate(data, 'res_fd'),
            ...splitDate(data, 'res_fd_from'),
            ...splitDate(data, 'res_fd_to'),
            ...splitDate(data, 'birth_fd'),
            ...splitDate(data, 'birth_fd_from'),
            ...splitDate(data, 'birth_fd_to'),
            ...splitDate(data, 'death_fd'),
            ...splitDate(data, 'death_fd_from'),
            ...splitDate(data, 'death_fd_to'),
            ...splitDate(data, 'emigration_fd'),
            ...splitDate(data, 'emigration_fd_from'),
            ...splitDate(data, 'emigration_fd_to'),
            ...splitDate(data, 'expulsion_fd'),
            ...splitDate(data, 'expulsion_fd_from'),
            ...splitDate(data, 'expulsion_fd_to'),
            ...splitDate(data, 'imprisonment_fd'),
            ...splitDate(data, 'imprisonment_fd_from'),
            ...splitDate(data, 'imprisonment_fd_to'),
            ...splitDate(data, 'deportation_fd'),
            ...splitDate(data, 'deportation_fd_from'),
            ...splitDate(data, 'deportation_fd_to'),
          }
          let patchedData = this.apiService.removeEmpty(data);
          for ( let item in patchedData ) {
            if ( patchedData[item] === 'true' ) { patchedData[item] = true; }
            else if ( patchedData[item] === 'false' ) { patchedData[item] = false; }
          }
          this.searchForm.patchValue(patchedData);
          this.mapControlForm.patchValue(patchedData);
          if ( patchedData.hasOwnProperty("map_date") ) {
            this.scrollToMapDate(patchedData.map_date);
          }
          this.dateFormatSwitchers = {
            residence: Boolean(patchedData?.res_single_fd),
            birth: Boolean(patchedData?.birth_single_fd),
            death: Boolean(patchedData?.death_single_fd),
            deportation: Boolean(patchedData?.deportation_single_fd),
            emigration: Boolean(patchedData?.emigration_single_fd),
            expulsion: Boolean(patchedData?.expulsion_single_fd),
            imprisonment: Boolean(patchedData?.imprisonment_single_fd),
          };
        }
      }
    );



    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show progress spinner or progress bar
          // console.log('navigation start', event)
      }

      if (event instanceof NavigationEnd) {
          // Hide progress spinner or progress bar
          // console.log('navigation end', event)
          this.currentRoute = event.url;
          var url = event.url.substring(1);

          if (this.currentRoute) {
            this.fragmentSubscription = this.route.fragment.subscribe(fragment => this.fragment = fragment);
            setTimeout(() => {
              this.fragmentSubscription.unsubscribe();
              document.querySelector("#"+this.fragment)?.scrollIntoView({ block: 'start' });
            }, 800)
          }

          if ( url.split('#').length > 0 ) {
            url = url.split('#')[0];
          };
          if ( url.split('?').length > 0 ) {
            url = url.split('?')[0];
          };

          // console.log('url: ', url);
          if (url != '' && url != null) {
            // console.log('url: '+ url);
            if ( url.substring(0,5) == 'page/') {
              var slug = url.substring(5)
              // console.log('slug ' + slug);
              this.apiService.setPage(slug);
              this.apiService.setCurrentDisplay('page');
            }
            else if ( url.substring(0,4) == 'bio/' ) {
              // console.log('bio ' + url);
              if ( this.apiService.currentDisplay == 'landing-page' ){
                this.apiService.setCurrentDisplay('person-fullscreen');
                // this.setRightOverlayFullscreen(true);
              }
              else if ( !this.apiService.displayBiography ){
                this.apiService.setCurrentDisplay('person');
              }
              let personReference = url.substring(4); // (+) converts string 'id' to a number
              // console.log("Person reference", personReference, url);
              if ( this.personReference != personReference ) {
                this.personReference = personReference;
                this.apiService.setPersonReference(this.personReference);
              } 
              // In a real app: dispatch action to load the details here.
            }
          }
          else
          {
             this.apiService.setPage("");
             this.personReference = '';
          }
        }
    });
  }

  changeMapDate(event: any, year:number): void {
    // console.log(event);
    event.srcElement.labels[0].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
    this.apiService.setMapDate(this.mapControlForm.value.map_date)
  }

  closeRightOverlay(): void {
    if( this.apiService.currentDisplay == 'page' && this.lastDisplay == 'person') {
      this.apiService.setCurrentDisplay('person');
    }
    else {
      this.apiService.setCurrentDisplay('search');
      // if( this.rightOverlayFullscreen ) {
      //   this.applicationState.setRightOverlayFullscreen(false);
      // }
    }
  }

  clearSearch(complete=true): void {
    this.searchForm.reset();
    this.dateFormatSwitchers = {
      residence: false,
      birth: false,
      death: false,
      deportation: false,
      emigration: false,
      expulsion: false,
      imprisonment: false
    };
    this.onSubmit();
    this.apiService.currentSearchDisplay = 'base-search';
    // this.apiService.setSearchParameters(this.searchForm.value);
    // this.tabGroup.selectedIndex = 0;
  }

  clearSearchAtAdress(): void {
    // this.searchForm.reset();
    this.searchByAddress = false;
    // this.apiService.setSearchParameters(this.searchForm.value);
    // this.tabGroup.selectedIndex = 0;
  }

  ngAfterViewChecked(): void {
  //   if ( !this.scrolledToAnchorOnInit && this.fragment != ''){
  //     try {
  //       console.log("Trying this")
  //       document.querySelector("#"+this.fragment).scrollIntoView({ block: 'start' });
  //       this.scrolledToAnchorOnInit = true;
  //     } catch (e) {
  //       console.error(e, 'error');
  //     }
  //   }
  }

  clearFilterResults(): void {
    this.searchForm.controls.filter_results.reset();
    this.filterResultsChanged();
  }

  clearFilterResultsAtAdress(): void {
    this.filterResultsForm.reset();
    this.onSubmitFilterResultsAtAddress();
  }

  onSubmitFilterResults(force: boolean = false): void{
    let values = this.searchForm.value;
    values['filterResults'] = this.filterResultsForm.value.filterResults;
    let performSearch = false;
    if ( values['filterResults'] != null && values['filterResults'].length > 0 ){
      this.filterResultsFormClearButtonHidden = false;
      if (values['filterResults'].length > 2) {
        performSearch = true;
      }
    }
    else
    {
      performSearch = true;
      this.filterResultsFormClearButtonHidden = true;
    }
    if( performSearch || force ) {
      this.apiService.refreshAddressSearch = true;
      this.apiService.setSearchParameters(values);
    }
  }

  onSubmitFilterResultsAtAddress(): void{
    let values = this.searchForm.value;
    values['filterResults'] = this.filterResultsForm.value.filterResults;
    let performSearch = false;
    if ( values['filterResults'] != null && values['filterResults'].length > 0 ){
      this.filterResultsFormClearButtonHidden = false;
      if (values['filterResults'].length > 2) {
        performSearch = true;
      }
    }
    else
    {
      performSearch = true;
      this.filterResultsFormClearButtonHidden = true;
    }
    if ( this.searchForm.value.coordinates_show_all !== this.apiService.searchedAtCoordinates?.coordinates_show_all ) {
      performSearch = true;
      this.apiService.searchedAtCoordinates.coordinates_show_all = this.searchForm.value.coordinates_show_all;
    }
    if( performSearch ) {
      this.apiService.refreshAddressSearch = true;
      this.apiService.setSearchParameters(values, false);
      // this.apiService.searchAtCoordinates(null, true);
    }
  }

  typeof(x:any):string {
    return typeof x;
  }

  parseFloat(x:string, decimalPlaces: number): String{
    return parseFloat(x).toFixed(3);
  }

  onSubmit(fromSubmitButton=true) {
    // this.filterResultsForm.reset();
    // this.apiService.setCurrentDisplay('results');
    this.apiService.currentDisplay = 'search';
    this.apiService.currentSearchDisplay = 'results';
    this.searchByQuery = true;
    if ( fromSubmitButton ) {
      this.filterResultsForm.value.filterResults = '';
    }
    let values = this.searchForm.value;
    for (let dateFormat in this.dateFormatSwitchers) {
      let key = (dateFormat == 'residence') ? 'res' : dateFormat
      values[`${key}_single_fd`] = this.dateFormatSwitchers[dateFormat] == true
    }
    //   if (this.dateFormatSwitchers[dateFormat] == false){
    //     if (dateFormat == 'birth' || dateFormat == 'death') {
    //       if(values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'].toString().length > 0 || values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'].toString().length > 0 || values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_year'].toString().length > 0) {
    //         values['birth_death'][dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_day'] = values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'];
    //         values['birth_death'][dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_month'] = values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'];
    //         values['birth_death'][dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_year'] = values['birth_death'][dateFormat][dateFormat + 'date_from'][dateFormat + '_date_from_year'];
    //       }
    //     }
    //     else
    //     {
    //       if(values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'].toString().length > 0 || values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'].toString().length > 0 || values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_year'].toString().length > 0) {
    //         values[dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_day'] = values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'];
    //         values[dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_month'] = values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'];
    //         values[dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_year'] = values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_year'];
    //       }
    //     }
    //   }
    // }
    // console.log(values)
    // values = {
    //   ...values,
    //   res_single_fd: this.dateFormatSwitchers.residence,
    //   birth_single_fd: this.dateFormatSwitchers.birth,
    //   death_single_fd: this.dateFormatSwitchers.death,
    //   deportation_single_fd: this.dateFormatSwitchers.deportation,
    //   emigration_single_fd: this.dateFormatSwitchers.emigration,
    //   expulsion_single_fd: this.dateFormatSwitchers.expulsion,
    //   imprisonment_single_fd: this.dateFormatSwitchers.imprisonment,
    // };
    this.apiService.setSearchParameters(values);

    // console.log(this.searchForm.value)
  }

  onLocationFormSubmit() {
  //   this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_MAPS_API_KEY + '&address=' + this.locationForm.value.search_address)
  //       .subscribe(
  //         data => {
  //           // console.log(this.locationForm.value.search_address, data)
  //           if ( data['results'].length > 0 ) {
  //             console.log(data['results'][0]['geometry']['viewport']);
  //             this.apiService.setFitToBounds(data['results'][0]['geometry']['viewport'])
  //             this.locationNotFound = false;
  //           }
  //           else
  //           {
  //             this.locationNotFound = true;
  //           }
  //         },
  //         error => {
  //             console.error(error),
  //             this.locationNotFound = true;
  //           },
  //       );
  }
}


