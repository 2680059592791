<ng-template #donationOptions>
  <div class="donation-options">
    <h3>{{ 'supportUs.donationOptions' | translate }} </h3>
    <div class="donation-option bank-transfer">
      <div class="bold">{{ 'supportUs.viaBankTransfer' | translate }}:</div>
      <div class="margin-top">Tracing the Past e.V.</div>
      <div>Berliner Sparkasse</div>
      <div>Hackescher Markt 4, 10178 Berlin, Germany</div>
      <div class="margin-top">IBAN: DE90 1005 0000 0190 3505 55</div>
      <div>SWIFT-Code: BELADEBEXXX</div>
    </div>
    <div class="or">
      {{ 'labels.or' | translate }}
    </div>
    <div class="donation-option paypal">
      <div class="bold">{{ 'supportUs.viaPaypal' | translate }}:</div>
      <a href="{{ 'supportUs.paypalUrl' | translate }}" target="_blank" class="button button-paypal" (click)="activeMapControllOverlay=null">
          <img class="paypal-donation-button" src="/assets/paypal_logo.svg" alt="PayPal">
      </a>
    </div>
  </div>
</ng-template>

<div class="app-loading" [ngClass]="{hidden: apiService.initialized}">
  <div class="app-loading-spinner">
    <div class="logo-wrapper">
      <svg width="100%" height="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <path d="M38.83,25.301L38.83,17.606L44.846,17.606L44.846,41.219L59.103,41.219L59.103,46.395L42.184,46.395L42.184,42.43C42.001,42.443 41.816,42.453 41.627,42.459C41.438,42.466 41.259,42.469 41.09,42.469C39.957,42.469 39.28,42.362 39.059,42.147C38.961,42.052 38.884,41.892 38.83,41.668L38.83,29.266L42.184,29.266L42.184,25.301L38.83,25.301Z" style="fill:black;fill-rule:nonzero;"/>
        <path d="M33.121,29.266L33.121,46.395L27.516,46.395L27.516,26.922C27.516,26.362 27.522,25.578 27.535,24.569C27.548,23.56 27.555,22.782 27.555,22.235L22.106,46.395L16.266,46.395L10.856,22.235C10.856,22.782 10.862,23.56 10.875,24.569C10.888,25.578 10.895,26.362 10.895,26.922L10.895,46.395L5.289,46.395L5.289,17.606L14.039,17.606L19.274,40.243L24.469,17.606L33.121,17.606L33.121,25.301L30.25,25.301L30.25,29.266L33.121,29.266Z" style="fill:black;fill-rule:nonzero;"/>
      </svg>
    </div>
  </div>
</div>

<div class="outer-wrapper" [ngClass]="{ 'menu-open': apiService.menuOpen }">

  <div class="map-blur" [ngClass]="[apiService.currentDisplay, apiService.currentSearchDisplay, apiService.mainOverlayMinimized ? 'minimized' : '', apiService.menuOpen ? 'menu-open': '', apiService.displayBiography ? 'person' : '']" (click)="apiService.setCurrentDisplay('close-blur')"></div>
  <app-map-wrapper [mainMap]="true"></app-map-wrapper>

  <div class="mtl-main-map-overlay-wrapper" [ngClass]="{'split-screen': (apiService.currentSearchDisplay=='results' && tabularResults)}">
    <div class="mtl-map-overlay-wrapper main-overlay-wrapper" [ngClass]="[apiService.currentDisplay, apiService.currentSearchDisplay, apiService.mainOverlayMinimized ? 'minimized' : '', apiService.menuOpen ? 'menu-open': '', apiService.displayBiography ? 'person' : '']">
      <div class="blur" [ngClass]="{'hidden': apiService.currentDisplay=='landing-page'}"></div>
      <div class="main-overlay-navigation-buttons">
        <div class="button-icon menu-icon">
          <img src="/assets/menu.svg" class="information-icon" [ngClass]="{ 'active': apiService.menuOpen }" (click)="apiService.menuOpen=!apiService.menuOpen;">
        </div>
        <div class="button-icon search-icon">
          <img src="/assets/search.svg" class="search-app-search-icon" [ngClass]="{active: (apiService.currentDisplay!='landing-page' && apiService.currentDisplay!='map' && !rightOverlayFullscreen)}" (click)="apiService.setCurrentDisplay('search')">
        </div>
      </div>
      <div class="main-overlay-content main-overlay-header">
        <div class="button-placeholder"></div>
        <div class="main-overlay-title-bar">
          <img src="/assets/mtl.svg" class="main-overlay-mtl-logo" [ngClass]="{hidden: (apiService.currentDisplay=='landing-page' && !apiService.menuOpen)}" (click)="apiService.setCurrentDisplay('landing-page')" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">
          <span [ngClass]="{hidden: !(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='base-search')}">
            {{ 'labels.search' | translate }}
          </span>
          <span [ngClass]="{hidden: !(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='advanced-search')}">
            {{ 'labels.advancedSearch' | translate }}
          </span>
          <span [ngClass]="{hidden: !(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='results')}">
            {{ 'labels.results' | translate }}
          </span>
          <span [ngClass]="{hidden: !(apiService.currentDisplay=='page' && page.hasOwnProperty('title'))}">
            {{ page?.title }}
          </span>
          <div class="main-overlay-close-icon-wrapper icon"  (click)="apiService.setCurrentDisplay('close-main-overlay')">
            <img class="button-icon close-icon" src="/assets/close.svg">
          </div>
        </div>
      </div>
      <div class="main-overlay-content menu-wrapper" [ngClass]="{hidden: !apiService.menuOpen}">
        <div class="main-overlay-title-bar">
          <img src="/assets/mtl.svg" class="main-overlay-mtl-logo" [ngClass]="{hidden: (apiService.currentDisplay=='landing-page' && !apiService.menuOpen)}" (click)="apiService.setCurrentDisplay('landing-page')" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">
        </div>
        <ul class="menu">
          <li class="menu-item menu-item-align-top" [ngClass]="{'selected':(currentPage=='' && apiService.currentDisplay=='landing-page')}" (click)="apiService.setCurrentDisplay('landing-page'); apiService.menuOpen=false" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">
            {{ 'labels.projectDescription' | translate }}
          </li>
          <li class="menu-item menu-item-align-top" [ngClass]="{hidden: !resultsAvailable, 'selected':(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='results')}" (click)="apiService.setCurrentDisplay('results'); apiService.menuOpen=false">
            {{ 'labels.results' | translate }}
          </li>
          <li class="menu-item menu-item-align-top" [ngClass]="{'selected':(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='base-search')}" (click)="apiService.setCurrentDisplay('search'); apiService.menuOpen=false" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">
            {{ 'labels.search' | translate }}
          </li>
          <li class="menu-item menu-item-align-top" [ngClass]="{'selected':(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='advanced-search')}" (click)="apiService.setCurrentDisplay('advanced-search'); apiService.menuOpen=false" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">
            {{ 'labels.advancedSearch' | translate }}
          </li>
          <li *ngFor="let menuItem of menuItems" class="menu-item menu-item-align-top" (click)="apiService.setCurrentDisplay('page'); apiService.menuOpen=false;" [ngClass]="{'selected':(currentPage==menuItem.slug && apiService.currentDisplay=='page')}" [routerLink]="[ '/page/', menuItem.slug ]" [queryParamsHandling]="'preserve'">
            {{ menuItem.label }}
          </li>
          <li class="menu-item-spacer"></li>
          <li class="menu-item-last-viewed" *ngIf="lastViewedBiographies.length > 0">
            <span class="menu-last-viewed-title"> {{ uiTranslations?.lastViewedBiographies }} </span>
              <ul class="menu-last-viewed-wrapper">
              <li *ngFor="let lastViewedBiography of lastViewedBiographies.sort( compareFullnames )"  class="menu-item menu-item-align-top last-viewed-item" (click)="apiService.menuOpen=false; apiService.setCurrentDisplay('person_or_person_fullscreen');" [ngClass]="{selected:(apiService.personReference==lastViewedBiography.reference && ['person', 'person_fullscreen'].indexOf(currentDisplay) > -1 ) }" [routerLink]="['/bio/', lastViewedBiography?.reference ]" [queryParamsHandling]="'preserve'">
                  <span class="last-viewed-biographies-name">{{ getValueToDisplay(lastViewedBiography?.fullname) }}</span>
                  <span class="last-viewed-biographies-address">{{ getValueToDisplay(lastViewedBiography?.residence_on_map?.place_name_display_list) }}</span>
              </li>
            </ul>
          </li>
        </ul>
    
        
        <div class="language-switcher">
          <img src="/assets/translations.svg" class="translations-icon">
          <div class="language-choice-wrapper">
            <span *ngFor="let language of Object.keys(apiService.languageChoices)" class="menu-item language-choices-item" (click)="apiService.changeLanguageCode(language)" [ngClass]="{ selected: language == languageCode }">{{ apiService.languageChoices[language] }}</span>
          </div>
        </div>
      </div>
  
  
      <div class="main-overlay-content base-search-wrapper" [ngClass]="{hidden: !(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='base-search')}">
        <div class="form-header">
          <form [formGroup]="searchForm" class="search-form" (onSubmit)="onSubmit(true)">
            <input type="text"  class="form-control" id="forename" placeholder="{{ 'search.labels.forename' | translate }}" formControlName="forename" name="forename" [value]="searchForm.value.forename">
            <input type="text"  class="form-control" id="surname" placeholder="{{ 'search.labels.surname' | translate }}" formControlName="surname" name="surname" [value]="searchForm.value.surname">
            <div class="street-wrapper">
              <input type="text"  class="form-control" id="res_street" placeholder="{{ 'search.labels.street' | translate }}" formControlName="res_street" name="res_street" [value]="searchForm.value.res_street">
              <input type="text"  class="form-control streetnumber" id="res_streetnr" placeholder="{{ 'search.labels.streetnumber' | translate }}" formControlName="res_streetnr" name="res_streetnr" [value]="searchForm.value.res_streetnr">
            </div>
            <input type="text"  class="form-control" id="res_community" placeholder="{{ 'search.labels.community' | translate }}" formControlName="res_community" name="res_community" [value]="searchForm.value.res_community">
            <div class="button-wrapper">
              <button [ngClass]="{'loading': apiService.currentlyLoading_.list}" (click)="onSubmit(true);">
                <span>{{ 'labels.search' | translate }}</span>
              </button>
              <u class="button" (click)="apiService.setCurrentDisplay('advanced-search')">
                <span>{{ 'labels.advancedSearch' | translate }}</span>
            </u>
            </div>
            <span class="search-text">
              {{ 'search.labels.instructions' | translate }}
            </span>
          </form>
        </div>
      </div>

      <div class="main-overlay-content search-results-wrapper fill-available-height" [ngClass]="{hidden: !(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='results'), tabular: tabularResults}">
        <div class="search-result-content-wrapper fill-available-height">
          <div class="horizontal-scroll-wrapper mtl-scrollbar fill-available-height">
            <div class="search-result-list-wrapper">
              <div class="search-result-controlls">
                <div class="search-result-controlls-row-wrapper">
                  <button (click)="clearSearch(true)" [ngClass]="{hidden: apiService.searchByAddress.searchedAtCoordinates!==null}" class="grid-full-width search-result-clear-search-button">
                    <img src="/assets/clear.svg" class="search-result-clear clear-icon">
                    <span>
                      {{ 'labels.clearSearch' | translate }}
                    </span>
                  </button>
                  <button (click)="clearSearchByAddress()" [ngClass]="{hidden: !(apiService.searchByAddress.searchedAtCoordinates!==null && apiService.searchByQuery.results.length <= 0)}" class="grid-full-width search-result-clear-search-button">
                    <img src="/assets/clear.svg" class="search-result-clear clear-icon">
                    <span>
                      {{ 'labels.clearSearch' | translate }}
                    </span>
                  </button>
                  <button (click)="clearSearchByAddress()" [ngClass]="{hidden: !(apiService.searchByAddress.searchedAtCoordinates!==null && apiService.searchByQuery.results.length > 0)}" class="grid-full-width search-result-clear-search-button">
                    <img src="/assets/back.svg" class="search-result-clear clear-icon">
                    <span>
                      {{ 'labels.backToSearch' | translate }}
                    </span>
                  </button>
                  <button class="search-result-edit-search" [ngClass]="{hidden: searchByQuery==null}" (click)="apiService.setCurrentDisplay('advanced-search')">
                    <img class="button-icon search-results-edit-search-icon" src="/assets/edit.svg">
                    <span>{{ 'search.labels.editSearch' | translate }}</span>
                  </button>
                </div>
                <div class="filter-results-wrapper" *ngIf="apiService.currentSearchDisplay=='results'">
                  <form [formGroup]="searchForm" (ngSubmit)="onSubmitFilterResults()">
                    <div class="filter-results-search-wrapper">
                      <input type="input"  class="form-control" id="filter_results" placeholder="{{ 'search.labels.searchWithinResults' | translate | titlecase }}" (keyup)="filterResultsChanged()" formControlName="filter_results" name="filter_results">
                      <img class="filter-results-clear-icon button-icon inverted" src="/assets/close.svg" [ngClass]="{ hidden: !( typeof(searchForm.controls.filter_results.value) == 'string' && searchForm.controls.filter_results.value.length > 0 )}" (click)="clearFilterResults()">
                      <img src="/assets/search_dark.svg" class="button-icon">
                    </div>
                    <!-- <img class="filter-results-search-icon" src="/assets/search.svg" (click)="onSubmitFilterResults()"> -->
                    <!-- <input type="submit" value="{{searchUITranslations?.filter}}"> -->
                  </form>
                </div>

                <button class="search-result-restrict-to-map" [ngClass]="{hidden: apiService.searchByAddress.searchedAtCoordinates!==null, active: searchForm.controls.restrict_to_map_bounds.value, disabled: !apiService.consentGivenGoogleMaps_}" (click)="searchForm.controls.restrict_to_map_bounds.setValue(!searchForm.controls.restrict_to_map_bounds.value); onSubmitFilterResults();">
                  <img src="/assets/search_within_map_bounds.svg" class="search-result-restrict-label-with-left-icon-icon restrict-to-map-icon">
                  <span>{{ 'search.labels.restrictToMap' | translate }}</span>
                </button>

                <button (click)="clearFilterResults()" [ngClass]="{hidden: filterResultsFormClearButtonHidden}" class="grid-full-width search-result-clear-search-button">{{ 'labels.clearFilter' | translate }}</button>
              </div>
              <div class="search-results-loading-animation-wrapper" [ngClass]="{hidden: !apiService.currentlyLoading_.list}">
                <div class="search-results-loading-spinner"><div></div><div></div><div></div><div></div></div>
              </div>
              <div *ngIf="apiService.searchByAddress.searchedAtCoordinates !== null" class="searched-at-coordindates">
                <span class="title">
                  {{ 'labels.searchedAtCoordinates' | translate }}
                </span>
                <div class="map-marker place" [ngClass]="[apiService.searchByAddress.searchedAtCoordinates.event_type]"></div>
                <div class="label">
                  {{ 'labels.' + apiService.searchByAddress.searchedAtCoordinates.event_type | translate }}
                </div>
                <div class="coordinates">
                  <span>{{ 'labels.lat' | translate }}: {{ parseFloat(apiService.searchByAddress.searchedAtCoordinates.lat, 3) }}</span>
                  <span>{{ 'labels.lon' | translate }}: {{ parseFloat(apiService.searchByAddress.searchedAtCoordinates.lon, 3) }}</span>
                </div>
              </div>
                <table class="search-result-table">
                  <thead [ngClass]="{hidden: !tabularResults}">
                    <tr>
                      <th>
                        {{ 'search.table.name' | translate }}
                      </th>
                      <th>
                        {{ 'search.table.birthDate' | translate }}
                      </th>
                      <th>
                        {{ 'search.table.birthPlace' | translate }}
                      </th>
                      <th>
                        {{ 'search.table.deathDate' | translate }}
                      </th>
                      <th>
                        {{ 'search.table.deathPlace' | translate }}
                      </th>
                      <th>
                        {{ 'search.table.residence' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="apiService.searchByAddress.searchedAtCoordinates !== null">
                      <ng-container *ngFor="let result of apiService.searchByAddress.results; let i = index;">
                        <tr *ngIf="i==0" class="grid-full-width search-result-list-letter">
                          <td colspan="6">
                            <span>
                              {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="i>0 && getValueToDisplay(result.fullname_display_list).charAt(0).toUpperCase() != getValueToDisplay(apiService.searchByAddress.results[i-1].fullname_display_list).charAt(0).toUpperCase()" class="grid-full-width search-result-list-letter">
                          <td colspan="6">
                            <span>
                              {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
                            </span>
                          </td>
                        </tr>
                        <ng-container [ngTemplateOutlet]="personRow" [ngTemplateOutletContext]="{person: result}"></ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="apiService.searchByAddress.searchedAtCoordinates === null">
                      <ng-container *ngFor="let result of apiService.searchByQuery.results; let i = index;">
                        <tr *ngIf="i==0" class="grid-full-width search-result-list-letter">
                          <td colspan="6">
                            <span>
                              {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="i>0 && getValueToDisplay(result.fullname_display_list).charAt(0).toUpperCase() != getValueToDisplay(apiService.searchByQuery.results[i-1].fullname_display_list).charAt(0).toUpperCase()" class="grid-full-width search-result-list-letter">
                          <td colspan="6">
                            <span>
                              {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
                            </span>
                          </td>
                        </tr>
                        <ng-container [ngTemplateOutlet]="personRow" [ngTemplateOutletContext]="{person: result}"></ng-container>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              <!-- </div> -->
              <div *ngIf="(apiService.searchByQuery.results.length == 0 && apiService.searchByAddress.results.length == 0) && !apiService.currentlyLoading_.list" class="search-result-none-message-wrapper">
                <div class="search-result-no-results-found-wrapper">
                  <span class="search-result-no-results">{{ 'search.labels.noResults' | translate }}</span>
                  <div [innerHtml]="'search.labels.noResultsContribute' | translate"></div>
                  <a href="mailto:info@tracingthepast.org">info&#64;tracingthepast.org</a>
                </div>
              </div>
              <button *ngIf="apiService.searchByAddress.results.length == 0 && apiService.searchByQuery.next!=null" class="grid-full-width" [ngClass]="{loading: apiService.currentlyLoading_.next}" (click)="apiService.searchByQueryFetchNextPage()">{{ 'search.labels.loadMore' | translate }}</button>
              <button *ngIf="apiService.searchByAddress.results.length > 0 && apiService.searchByAddress.next!=null" class="grid-full-width" [ngClass]="{loading: apiService.currentlyLoading_.next}" (click)="apiService.searchByAddressFetchNextPage()">{{ 'search.labels.loadMore' | translate }}</button>
            </div>
          </div>
          <div class="search-results-footer">
            <div class="tabular-results-icon" [ngClass]="{active: tabularResults}" (click)="tabularResults=!tabularResults">
              <img src="/assets/table.svg" class="button-icon"> 
            </div>
            <div class="search-result-count" *ngIf="apiService.searchByAddress.searchedAtCoordinates === null && apiService.currentlyLoading_.list == false && apiService.searchByQuery.count >= 0">
              <img src="/assets/search_within_map_bounds.svg" *ngIf="searchForm.controls.restrict_to_map_bounds.value===true" class="search-result-count-restrict-to-map-icon">
              <span>
                {{ apiService.searchByQuery.count.toString() }} {{ 'search.labels.persons' | translate }}
              </span>
              <!-- TODO Add singular -->
            </div>
            <div class="search-result-count" *ngIf="apiService.searchByAddress.searchedAtCoordinates !== null && apiService.currentlyLoading_.list == false && apiService.searchByAddress.count >= 0">
              <span>
                {{ apiService.searchByAddress.count.toString() }} {{ 'search.labels.persons' | translate }}
              </span>
              <!-- TODO Add singular -->
            </div>
          </div>
        </div>
      </div>
      <div class="main-overlay-content advanced-search-wrapper" [ngClass]="{'hidden': !(apiService.currentDisplay=='search' && apiService.currentSearchDisplay=='advanced-search')}">
        <form [formGroup]="searchForm" (ngSubmit)="onSubmit(true)">
          <div class="advanced-search-content-wrapper">
            <div class="advanced-search-options-wrapper" *ngIf="apiService.searchByQuery.results.length > 0">
              <div class="advanced-search-back-to-results-wrapper" (click)="apiService.currentSearchDisplay='results'"><img src="/assets/back.svg"><span>{{ 'search.labels.backToResults' | translate }}</span></div>
            </div>
            <div class="search-column-wrapper">
              <div class="search-field-grouping">
                <div class="search-grouping-title">
                  {{ 'search.labels.name' | translate }}
                </div>
                <input type="text"  class="form-control" id="forename" [placeholder]="'search.labels.forename' | translate" formControlName="forename" name="forename" [value]="searchForm.value.forename">
                <input type="text"  class="form-control" id="surname" [placeholder]="'search.labels.surname' | translate" formControlName="surname" name="surname" [value]="searchForm.value.surname">
                <input type="text"  class="form-control" id="maidenname" [placeholder]="'search.labels.maidenname' | translate" formControlName="maidenname" name="maidenname" [value]="searchForm.value.maidenname">
              </div>
              <div class="search-grouping-title">
                {{ 'search.labels.residence' | translate }}
              </div>
              <div class="search-field-grouping">
                <div class="date-title-search-selector-wrapper">
                  <span class="field-group-title">{{ 'search.labels.residenceDate' | translate }}</span> 
                  <div class="date-search-selector-wrapper">
                      <label (click)="dateFormatSwitchers.residence = true" [ngClass]="{active: dateFormatSwitchers.residence}">
                        {{ 'search.labels.singleDate' | translate }}
                      </label>
                      <label class="toggle-switch date-or-date-range-toggle">
                        <input id="date-switch-residence" type="checkbox" [checked]="dateFormatSwitchers.residence !== true" (click)="dateFormatSwitchers.residence=!dateFormatSwitchers.residence" />
                        <span class="slider round"></span>
                      </label>
                      <label (click)="dateFormatSwitchers.residence = false" [ngClass]="{active: !dateFormatSwitchers.residence}">
                        {{ 'search.labels.dateRange' | translate }}
                      </label>
                  </div>
                </div>
                <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.residence}">
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="res_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="res_fd_day" name="res_fd_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="res_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="res_fd_month" name="res_fd_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="res_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="res_fd_year" name="res_fd_year">
                  </div>
                </div>
                <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.residence}">
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="res_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="res_fd_from_day" name="res_fd_from_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="res_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="res_fd_from_month" name="res_fd_from_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="res_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="res_fd_from_year" name="res_fd_from_year">
                  </div>
                  <div class="search-field-date-divider">
                    -
                  </div>
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="res_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="res_fd_to_day" name="res_fd_to_day" [value]="searchForm.value.res_fd_to_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="res_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="res_fd_to_month" name="res_fd_to_month" [value]="searchForm.value.res_fd_to_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="res_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="res_fd_to_year" name="res_fd_to_year" [value]="searchForm.value.res_fd_to_year">
                  </div>
                </div>
                <div class="street-wrapper">
                  <input type="text"  class="form-control" id="res_street" [placeholder]="'search.labels.street' | translate" formControlName="res_street" name="res_street" [value]="searchForm.value.res_street">
                  <input type="text"  class="form-control streetnumber" id="res_streetnr" [placeholder]="'search.labels.streetnumber' | translate" formControlName="res_streetnr" name="res_streetnr" [value]="searchForm.value.res_streetnr">
                </div>
                <input type="text"  class="form-control" id="res_community" [placeholder]="'search.labels.community' | translate" formControlName="res_community" name="res_community" [value]="searchForm.value.res_community">
                <input type="text"  class="form-control" id="res_quarter" [placeholder]="'search.labels.quarter' | translate" formControlName="res_quarter" name="res_quarter">
                <input type="text"  class="form-control" id="res_district" [placeholder]="'search.labels.district' | translate" formControlName="res_district" name="res_district">
                <input type="text"  class="form-control" id="res_state" [placeholder]="'search.labels.state' | translate" formControlName="res_state" name="res_state">
                <input type="text"  class="form-control" id="res_country" [placeholder]="'search.labels.country' | translate" formControlName="res_country" name="res_country">
            </div>
          </div>

          <div class="search-column-wrapper">
            <div class="search-field-grouping">
              <div class="search-grouping-title">
                {{ 'search.labels.birthDeath' | translate }}
              </div>
              <div class="search-field-grouping">
                <div class="date-title-search-selector-wrapper">
                  <span class="field-group-title">{{ 'search.labels.birthDate' | translate }}</span> 
                  <div class="date-search-selector-wrapper">
                    <label (click)="dateFormatSwitchers.birth = true" [ngClass]="{active: dateFormatSwitchers.birth}">
                      {{ 'search.labels.singleDate' | translate }}
                    </label>
                    <label class="toggle-switch date-or-date-range-toggle">
                      <input id="date-switch-birth" type="checkbox" [checked]="!dateFormatSwitchers.birth" (change)="dateFormatSwitchers.birth=!dateFormatSwitchers.birth"/>
                      <span class="slider round"></span>
                    </label>
                    <label (click)="dateFormatSwitchers.birth = false" [ngClass]="{active: !dateFormatSwitchers.birth}">
                      {{ 'search.labels.dateRange' | translate }}
                    </label>
                  </div>
                </div>
                <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.birth}">
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="birth_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="birth_fd_day" name="birth_fd_day" [value]="searchForm.value.birth_fd_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="birth_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="birth_fd_month" name="birth_fd_month" [value]="searchForm.value.birth_fd_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="birth_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="birth_fd_year" name="birth_fd_year" [value]="searchForm.value.birth_fd_year">
                  </div>
                </div>
                <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.birth}">
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="birth_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="birth_fd_from_day" name="birth_fd_from_day" [value]="searchForm.value.birth_fd_from_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="birth_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="birth_fd_from_month" name="birth_fd_from_month" [value]="searchForm.value.birth_fd_from_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="birth_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="birth_fd_from_year" name="birth_fd_from_year" [value]="searchForm.value.birth_fd_from_year">
                  </div>
                  <div class="search-field-date-divider">
                    -
                  </div>
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="birth_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="birth_fd_to_day" name="birth_fd_to_day" [value]="searchForm.value.birth_fd_to_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="birth_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="birth_fd_to_month" name="birth_fd_to_month" [value]="searchForm.value.birth_fd_to_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="birth_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="birth_fd_to_year" name="birth_fd_to_year" [value]="searchForm.value.birth_fd_to_year">
                  </div>
                </div>
                <input type="text"  class="form-control" id="birth_place" placeholder="{{ 'search.labels.birthPlace' | translate }}" formControlName="birth_place" name="birth_place">
              </div>
              <div class="date-title-search-selector-wrapper">
                <span class="field-group-title">{{ 'search.labels.deathDate' | translate }}</span> 
                <div class="date-search-selector-wrapper">
                  <label (click)="dateFormatSwitchers.death = true" [ngClass]="{active: dateFormatSwitchers.death}">
                    {{ 'search.labels.singleDate' | translate }}
                  </label>
                  <label class="toggle-switch date-or-date-range-toggle">
                    <input id="date-switch-death" type="checkbox" [checked]="!dateFormatSwitchers.death" (change)="dateFormatSwitchers.death=!dateFormatSwitchers.death"/>
                    <span class="slider round"></span>
                  </label>
                  <label (click)="dateFormatSwitchers.death = false" [ngClass]="{active: !dateFormatSwitchers.death}">
                    {{ 'search.labels.dateRange' | translate }}
                  </label>
                </div>
              </div>
              <div class="search-field-grouping">
                <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.death}">
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="death_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="death_fd_day" name="death_fd_day" [value]="searchForm.value.death_fd_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="death_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="death_fd_month" name="death_fd_month" [value]="searchForm.value.death_fd_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="death_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="death_fd_year" name="death_fd_year" [value]="searchForm.value.death_fd_year">
                  </div>
                </div>
                <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.death}">
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="death_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="death_fd_from_day" name="death_fd_from_day" [value]="searchForm.value.death_fd_from_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="death_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="death_fd_from_month" name="death_fd_from_month" [value]="searchForm.value.death_fd_from_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="death_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="death_fd_from_year" name="death_fd_from_year" [value]="searchForm.value.death_fd_from_year">
                  </div>
                  <div class="search-field-date-divider">
                    -
                  </div>
                  <div class="search-date-grouping">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="death_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="death_fd_to_day" name="death_fd_to_day" [value]="searchForm.value.death_fd_to_day">
                    <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="death_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="death_fd_to_month" name="death_fd_to_month" [value]="searchForm.value.death_fd_to_month">
                    <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="death_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="death_fd_to_year" name="death_fd_to_year" [value]="searchForm.value.death_fd_to_year">
                  </div>
                </div>
                <input type="text"  class="form-control" id="death_place" placeholder="{{ 'search.labels.deathPlace' | translate }}" formControlName="death_place" name="death_place">
              </div>
            </div>
            <div class="search-field-grouping">
              <div class="search-grouping-title">
                {{ 'search.labels.deportation' | translate }}
              </div>
              <div class="date-title-search-selector-wrapper">
                <span class="field-group-title">{{ 'search.labels.deportationDate' | translate }}</span> 
                <div class="date-search-selector-wrapper">
                  <label (click)="dateFormatSwitchers.deportation = true" [ngClass]="{active: dateFormatSwitchers.deportation}">
                    {{ 'search.labels.singleDate' | translate }}
                  </label>
                  <label class="toggle-switch date-or-date-range-toggle">
                    <input id="date-switch-deportation" type="checkbox" [checked]="!dateFormatSwitchers.deportation" (change)="dateFormatSwitchers.deportation=!dateFormatSwitchers.deportation"/>
                    <span class="slider round"></span>
                  </label>
                  <label (click)="dateFormatSwitchers.deportation = false" [ngClass]="{active: !dateFormatSwitchers.deportation}">
                    {{ 'search.labels.dateRange' | translate }}
                  </label>
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.deportation}">
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="deportation_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="deportation_fd_day" name="deportation_fd_day" [value]="searchForm.value.deportation_fd_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="deportation_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="deportation_fd_month" name="deportation_fd_month" [value]="searchForm.value.deportation_fd_month">
                  <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="deportation_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="deportation_fd_year" name="deportation_fd_year" [value]="searchForm.value.deportation_fd_year">
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.deportation}">
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="deportation_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="deportation_fd_from_day" name="deportation_fd_from_day" [value]="searchForm.value.deportation_fd_from_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="deportation_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="deportation_fd_from_month" name="deportation_fd_from_month" [value]="searchForm.value.deportation_fd_from_month">
                  <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="deportation_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="deportation_fd_from_year" name="deportation_fd_from_year" [value]="searchForm.value.deportation_fd_from_year">
                </div>
                <div class="search-field-date-divider">
                  -
                </div>
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="deportation_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="deportation_fd_to_day" name="deportation_fd_to_day" [value]="searchForm.value.deportation_fd_to_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="deportation_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="deportation_fd_to_month" name="deportation_fd_to_month" [value]="searchForm.value.deportation_fd_to_month">
                  <input type="text" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="deportation_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="deportation_fd_to_year" name="deportation_fd_to_year" [value]="searchForm.value.deportation_fd_to_year">
                </div>
              </div>
              <input type="text"  class="form-control" id="deportation_place_from" [placeholder]="'search.labels.deportationPlaceFrom' | translate" formControlName="deportation_place_from" name="deportation_place_from">
              <input type="text"  class="form-control" id="deportation_place" [placeholder]="'search.labels.deportationPlaceTo' | translate" formControlName="deportation_place" name="deportation_place">
            </div>
            <div class="search-field-grouping">
              <div class="search-grouping-title">
                {{ 'search.labels.emigration' | translate }}
              </div>
              <div class="date-title-search-selector-wrapper">
                <span class="field-group-title">{{ 'search.labels.emigrationDate' | translate }}</span> 
                <div class="date-search-selector-wrapper">
                  <label (click)="dateFormatSwitchers.emigration = true" [ngClass]="{active: dateFormatSwitchers.emigration}">
                    {{ 'search.labels.singleDate' | translate }}
                  </label>
                  <label class="toggle-switch date-or-date-range-toggle">
                    <input id="date-switch-emigration" type="checkbox" [checked]="!dateFormatSwitchers.emigration" (change)="dateFormatSwitchers.emigration=!dateFormatSwitchers.emigration"/>
                    <span class="slider round"></span>
                  </label>
                  <label (click)="dateFormatSwitchers.emigration = false" [ngClass]="{active: !dateFormatSwitchers.emigration}">
                    {{ 'search.labels.dateRange' | translate }}
                  </label>
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.emigration}">
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="emigration_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="emigration_fd_day" name="emigration_fd_day" [value]="searchForm.value.emigration_fd_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="emigration_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="emigration_fd_month" name="emigration_fd_month" [value]="searchForm.value.emigration_fd_month">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="emigration_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="emigration_fd_year" name="emigration_fd_year" [value]="searchForm.value.emigration_fd_year">
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.emigration}">
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="emigration_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="emigration_fd_from_day" name="emigration_fd_from_day" [value]="searchForm.value.emigration_fd_from_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="emigration_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="emigration_fd_from_month" name="emigration_fd_from_month" [value]="searchForm.value.emigration_fd_from_month">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="emigration_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="emigration_fd_from_year" name="emigration_fd_from_year" [value]="searchForm.value.emigration_fd_from_year">
                </div>
                <div class="search-field-date-divider">
                  -
                </div>
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="emigration_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="emigration_fd_to_day" name="emigration_fd_to_day" [value]="searchForm.value.emigration_fd_to_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="emigration_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="emigration_fd_to_month" name="emigration_fd_to_month" [value]="searchForm.value.emigration_fd_to_month">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="emigration_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="emigration_fd_to_year" name="emigration_fd_to_year" [value]="searchForm.value.emigration_fd_to_year">
                </div>
              </div>
              <input type="text"  class="form-control" id="emigration_place_from" [placeholder]="'search.labels.emigrationPlaceFrom' | translate" formControlName="emigration_place_from" name="emigration_place_from">
              <input type="text"  class="form-control" id="emigration_place" [placeholder]="'search.labels.emigrationPlaceTo' | translate" formControlName="emigration_place" name="emigration_place">
            </div>
          </div>

          <div class="search-column-wrapper">
            <div class="search-field-grouping">
              <div class="search-grouping-title">
                {{ 'search.labels.expulsion' | translate }}
              </div>
              <div class="date-title-search-selector-wrapper">
                <span class="field-group-title">{{ 'search.labels.expulsionDate' | translate }}</span> 
                <div class="date-search-selector-wrapper">
                  <label (click)="dateFormatSwitchers.expulsion = true" [ngClass]="{active: dateFormatSwitchers.expulsion}">
                    {{ 'search.labels.singleDate' | translate }}
                  </label>
                  <label class="toggle-switch date-or-date-range-toggle">
                    <input id="date-switch-expulsion" type="checkbox" [checked]="!dateFormatSwitchers.expulsion" (change)="dateFormatSwitchers.expulsion=!dateFormatSwitchers.expulsion"/>
                    <span class="slider round"></span>
                  </label>
                  <label (click)="dateFormatSwitchers.expulsion = false" [ngClass]="{active: !dateFormatSwitchers.expulsion}">
                    {{ 'search.labels.dateRange' | translate }}
                  </label>
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.expulsion}">
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="expulsion_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="expulsion_fd_day" name="expulsion_fd_day" [value]="searchForm.value.expulsion_fd_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="expulsion_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="expulsion_fd_month" name="expulsion_fd_month" [value]="searchForm.value.expulsion_fd_month">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="expulsion_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="expulsion_fd_year" name="expulsion_fd_year" [value]="searchForm.value.expulsion_fd_year">
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.expulsion}">
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="expulsion_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="expulsion_fd_from_day" name="expulsion_fd_from_day" [value]="searchForm.value.expulsion_fd_from_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="expulsion_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="expulsion_fd_from_month" name="expulsion_fd_from_month" [value]="searchForm.value.expulsion_fd_from_month">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="expulsion_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="expulsion_fd_from_year" name="expulsion_fd_from_year" [value]="searchForm.value.expulsion_fd_from_year">
                </div>
                <div class="search-field-date-divider">
                  -
                </div>
                <div class="search-date-grouping">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="expulsion_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="expulsion_fd_to_day" name="expulsion_fd_to_day" [value]="searchForm.value.expulsion_fd_to_day">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="expulsion_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="expulsion_fd_to_month" name="expulsion_fd_to_month" [value]="searchForm.value.expulsion_fd_to_month">
                  <input type="text" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="expulsion_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="expulsion_fd_to_year" name="expulsion_fd_to_year" [value]="searchForm.value.expulsion_fd_to_year">
                </div>
              </div>
              <input type="text"  class="form-control" id="emigration_place" [placeholder]="'search.labels.expulsionPlace' | translate" formControlName="expulsion_place" name="expulsion_place">
            </div>
            <div class="search-field-grouping">
              <div class="search-grouping-title">
                {{ 'search.labels.imprisonment' | translate }}
              </div>
              <div class="date-title-search-selector-wrapper">
                <span class="field-group-title">{{ 'search.labels.imprisonmentDate' | translate }}</span> 
                <div class="date-search-selector-wrapper">
                  <label (click)="dateFormatSwitchers.imprisonment = true" [ngClass]="{active: dateFormatSwitchers.imprisonment}">
                    {{ 'search.labels.singleDate' | translate }}
                  </label>
                  <label class="toggle-switch date-or-date-range-toggle">
                    <input id="date-switch-imprisonment" type="checkbox" [checked]="!dateFormatSwitchers.imprisonment" (change)="dateFormatSwitchers.imprisonment=!dateFormatSwitchers.imprisonment"/>
                    <span class="slider round"></span>
                  </label>
                  <label (click)="dateFormatSwitchers.imprisonment = false" [ngClass]="{active: !dateFormatSwitchers.imprisonment}">
                    {{ 'search.labels.dateRange' | translate }}
                  </label>
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: !dateFormatSwitchers.imprisonment}">
                <div class="search-date-grouping">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="imprisonment_fd_day" [placeholder]="'search.labels.day' | translate" formControlName="imprisonment_fd_day" name="imprisonment_fd_day" [value]="searchForm.value.imprisonment_fd_day">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="imprisonment_fd_month" [placeholder]="'search.labels.month' | translate" formControlName="imprisonment_fd_month" name="imprisonment_fd_month" [value]="searchForm.value.imprisonment_fd_month">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="imprisonment_fd_year" [placeholder]="'search.labels.year' | translate" formControlName="imprisonment_fd_year" name="imprisonment_fd_year" [value]="searchForm.value.imprisonment_fd_year">
                </div>
              </div>
              <div class="search-field-from-to-grouping" [ngClass]="{hidden: dateFormatSwitchers.imprisonment}">
                <div class="search-date-grouping">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="imprisonment_fd_from_day" [placeholder]="'search.labels.day' | translate" formControlName="imprisonment_fd_from_day" name="imprisonment_fd_from_day" [value]="searchForm.value.imprisonment_fd_from_day">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="imprisonment_fd_from_month" [placeholder]="'search.labels.month' | translate" formControlName="imprisonment_fd_from_month" name="imprisonment_fd_from_month" [value]="searchForm.value.imprisonment_fd_from_month">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="imprisonment_fd_from_year" [placeholder]="'search.labels.year' | translate" formControlName="imprisonment_fd_from_year" name="imprisonment_fd_from_year" [value]="searchForm.value.imprisonment_fd_from_year">
                </div>
                <div class="search-field-date-divider">
                  -
                </div>
                <div class="search-date-grouping">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="imprisonment_fd_to_day" [placeholder]="'search.labels.day' | translate" formControlName="imprisonment_fd_to_day" name="imprisonment_fd_to_day" [value]="searchForm.value.imprisonment_fd_to_day">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="imprisonment_fd_to_month" [placeholder]="'search.labels.month' | translate" formControlName="imprisonment_fd_to_month" name="imprisonment_fd_to_month" [value]="searchForm.value.imprisonment_fd_to_month">
                  <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="imprisonment_fd_to_year" [placeholder]="'search.labels.year' | translate" formControlName="imprisonment_fd_to_year" name="imprisonment_fd_to_year" [value]="searchForm.value.imprisonment_fd_to_year">
                </div>
              </div>
              <input type="text"  class="form-control" id="imprisonment_place" [placeholder]="'search.labels.imprisonmentPlace' | translate" formControlName="imprisonment_place" name="imprisonment_place">
            </div>

            <div class="search-tips-wrapper">
              <div class="search-tips-title">
                <span>{{ 'search.labels.searchTips' | translate }}:</span>
              </div>
              <ul class="search-tips" [innerHTML]="'search.tips' | translate">
              </ul>
              <div class="search-tip-link">
                <a href="/guide"><span>{{ 'labels.userGuide' | translate }}</span></a>
              </div>
            </div>

          </div>
        </div>
        <div class="advanced-search-clear-search button" (click)="clearSearch(true);" *ngIf="apiService.searchByQuery.results.length > 0"><img src="/assets/clear.svg"><span>{{ 'labels.clearSearch' | translate }}</span></div>
        <input type="submit" value="{{ 'labels.search' | translate }}" [ngClass]="{loading: apiService.currentlyLoading_.list}" (click)="onSubmit(true)">
        </form>
      </div>
      <!-- <div class="search-wrapper" [ngClass]="{'hidden': appSearchDisplayConditions.indexOf(currentDisplay) < 0 }">
        </div>
      </div> -->
  
      <div *ngIf="apiService.currentDisplay=='landing-page'" class="main-overlay-content landing-page">
        <div class="landing-page-header">
          <ng-element *ngIf="cooperationCredits">
            <span class="landing-page-cooperation-partner">{{ getValueToDisplay(cooperationPartnerName) }}</span>
            <span class="landing-page-cooperation-credit">{{ 'labels.InCooperationWith' | translate }}</span>
          </ng-element>
          <span class="landing-page-title">{{ 'labels.MappingTheLives' | translate }}</span>
          <span class="landing-page-subtitle">{{ 'labels.ACentralMemorialForThePersecuted' | translate }}</span>
          <span class="landing-page-description">{{ 'landingPage.description' | translate }}</span>
        </div>
        <button class="button" (click)="apiService.setCurrentDisplay('search')">{{ 'landingPage.buttonLabel' | translate }}</button>
      </div>
  
      <div *ngIf="apiService.currentDisplay=='page'" class="main-overlay-content flat-page-wrapper mtl-scrollbar">
        <div class="page-content flatpage" [ngClass]="[currentPage]">
          <div class="loading-spinner-wrapper" *ngIf="!page.hasOwnProperty('label')"><div class="loading-spinner"><div></div><div></div><div></div><div></div></div></div>
          <div class="page-content-flatpage" *ngIf="page.hasOwnProperty('label')" [innerHTML]="page?.content">
          </div>
          <ng-element *ngIf="currentPage=='support-us'" class="align-end">
            <hr>
            <ng-template [ngTemplateOutlet]="donationOptions"></ng-template> 
          </ng-element>
          <ng-element *ngIf="currentPage=='imprint'">
            
            <h3 id="technical-realisation">{{ 'labels.technicalRealisationBy' | translate }}</h3>
            <div class="page-technical-realisation-wrapper">
              <a target="_blank" href="https://lux-lab.at" class="page-technical-realisation-box">
                {{ 'labels.luxLab' | translate }}
                <img class="page-technical-realisation-logo" src="/assets/LUX.svg">
              </a>
            </div>
            <hr>
            <h3 class="page-privacy-settings-header" id="privacy-settings">{{ 'labels.privacySettings' | translate }}</h3>
            <div class="page-privacy-settings-wrapper">
              <p class="consent-form-text">{{ 'gdprConsent.text' | translate }}</p>
              <p class="consent-form-further-information">{{ 'gdprConsent.furtherInformationText' | translate }}</p>
              <a target="_blank" href="{{ 'gdprConsent.furtherInformationLink' | translate }}">{{ 'gdprConsent.furtherInformationLink' | translate }}</a>
              <p>{{ 'gdprConsent.usedCookies' | translate }}</p>
              
              <div class="privacy-settings-container">
                <button [disabled]="apiService.permanentConsentGivenGoogleMaps_" (click)="apiService.revokePermanentConsent()" [ngClass]="{disabled: apiService.permanentConsentGivenGoogleMaps_}">
                  {{ 'gdprConsent.revokeConsent' | translate }}
                </button>
              </div>
            </div>
          </ng-element>
        </div>
      </div>
    </div>
  
    <div class="mtl-map-overlay-wrapper map-overlay-right-wrapper" [ngClass]="{hidden: !apiService.displayBiography, fullscreen: apiService.displayBiographyFullscreen}">
      <div class="close-icon-wrapper icon" (click)="apiService.setCurrentDisplay('close-biography')" [routerLink]="[rightOverlayRedirectToOnClose]" [queryParamsHandling]="'preserve'">
        <img class="menu-bar-icon button-icon close-icon inverted" src="/assets/close.svg">
      </div>
      <app-person *ngIf="apiService.displayBiography"></app-person>
    </div>
  </div>

  <div class="mtl-map-overlay-wrapper map-options-button-wrapper icon support-button" (click)="toggleMapControllOverlay('support-us')" [ngClass]="{active: activeMapControllOverlay == 'support-us'}">
    <img src="/assets/support_mtl_blue.svg" class="support-icon button-icon">
  </div>
  <div class="mtl-map-overlay-wrapper map-options-button-wrapper map-options-zoom" [ngClass]="{disabled: !loadMap}">
    <div class="icon map-zoom zoom-in button-icon" (click)="apiService.changeMapZoom(1)" [ngClass]="{disabled: apiService.zoom >= apiService.mapOptions.maxZoom }">
      <svg viewBox="0 0 20 20">
        <line x1="0" x2="20" y1="10" y2="10"></line>
        <line x1="10" x2="10" y1="0" y2="20"></line>
      </svg>
    </div>
    <svg viewBox="0 0 20 2" class="divider">
      <line x1="0" x2="20" y1="1" y2="1"></line>
    </svg>
    <div class="icon map-zoom zoom-out button-icon"(click)="apiService.changeMapZoom(-1)" [ngClass]="{disabled: apiService.zoom <= apiService.mapOptions.minZoom }">
      <svg viewBox="0 0 20 20">
        <line x1="0" x2="20" y1="10" y2="10"></line>
      </svg>
    </div>
  </div>

  <div class="mtl-map-overlay-wrapper map-options-button-wrapper icon zoom-to-button" (click)="toggleMapControllOverlay('zoom-to-position')" [ngClass]="{active: activeMapControllOverlay == 'zoom-to-position', disabled: !loadMap}">
    <img src="/assets/zoom_to.svg" class="search-app-search-icon button-icon">
  </div>

  <div class="mtl-map-overlay-wrapper map-options-button-wrapper icon map-aggregations-button" (click)="toggleMapControllOverlay('map-aggregations')" [ngClass]="{active: activeMapControllOverlay == 'map-aggregations', disabled: !loadMap}">
    <svg viewBox="0 0 40 40" style="stroke: #fff;" class="map-options-button button-icon" [ngClass]="{active: mapOptionsAggregationsExpanded}">
      <g transform="matrix(1.4,0,0,1.16883,-11.5,-5.51948)">
          <path d="M22.5,9L35,16L22.5,23L10,16L22.5,9Z" style="stroke-width:1.16px;" class="aggregation-layers-icon residence" [ngClass]="{'active': apiService.mapAggregations.includes('residence')}"/>
      </g>
      <path d="M30.208,16.591L37.5,20L20,28.182L2.5,20L9.792,16.591L20,21.364L30.208,16.591Z" style="stroke-width:1.5px;" class="aggregation-layers-icon emigration" [ngClass]="{'active': apiService.mapAggregations.includes('emigration')}"/>
      <path d="M30.208,23.409L37.5,26.818L20,35L2.5,26.818L9.792,23.409L20,28.182L30.208,23.409Z" style="stroke-width:1.5px;" class="aggregation-layers-icon deportation" [ngClass]="{'active': apiService.mapAggregations.includes('deportation')}"/>
    </svg> 
  </div>

  <div class="mtl-map-overlay-wrapper map-options-button-wrapper icon timeline-button" (click)="toggleMapControllOverlay('map-timeline')" [ngClass]="{active: activeMapControllOverlay == 'map-timeline', disabled: !loadMap}">  
    <svg viewBox="0 0 40 40" class="map-options-button button-icon" [ngClass]="{active: mapOptionsTimelineExpanded}">
      <rect x="1" y="3" width="38" height="36" rx="3" stroke-width="2" stroke="#fff" fill="transparent"></rect>
      <rect x="7" y="1" width="1" height="6" rx="5" stroke-width="2" stroke="#fff" fill="#fff"></rect>
      <rect x="15" y="1" width="1" height="6" rx="5" stroke-width="2" stroke="#fff" fill="#fff"></rect>
      <rect x="24" y="1" width="1" height="6" rx="5" stroke-width="2" stroke="#fff" fill="#fff"></rect>
      <rect x="32" y="1" width="1" height="6" rx="5" stroke-width="2" stroke="#fff" fill="#fff"></rect>
      <line x1="0" x2="40" y1="12" y2="12" stroke-width="1" stroke="#fff"></line>
      <rect x="4" y="16" width="4" height="4" fill="#aaa"></rect>
      <rect x="11" y="16" width="4" height="4" fill="#aaa"></rect>
      <rect x="18" y="16" width="4" height="4" fill="#aaa"></rect>
      <rect x="25" y="16" width="4" height="4" fill="#aaa"></rect>
      <rect x="32" y="16" width="4" height="4" fill="#fff"></rect>
      <text x="20" y="31" text-anchor="middle" dominant-baseline="middle" style="font-family:'Roboto', 'Helvetica Neue';font-weight:400;font-size:20px; color: #fff;" fill="#fff" *ngIf="!mapControlForm.value.map_date">
        ~
      </text>
      <text x="20" y="31" text-anchor="middle" dominant-baseline="middle" style="font-family:'Roboto', 'Helvetica Neue';font-weight:400;font-size:14px; color: #fff;" fill="#fff" *ngIf="mapControlForm.value.map_date">
        {{ mapControlForm.value.map_date }}
      </text>
    </svg>
  </div>

  <div class="map-options-and-loading-indicator-wrapper">
    <div class="mtl-map-overlay-wrapper loading-indicator" [ngClass]="{hidden: !apiService.currentlyLoading_.map}">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <div>{{ 'labels.loading' | translate }}</div>
    </div>
    <div class="mtl-map-overlay-wrapper map-options-content-wrapper" [ngClass]="{hidden: !activeMapControllOverlay}" [class]="activeMapControllOverlay">
      <div class="mtl-horizontal-scroll-fade-overlay" [ngClass]="{ hidden: activeMapControllOverlay != 'support-us' }">
        <div class="support-us-wrapper">
          <div class="support-us-close-icon-wrapper button-icon"  (click)="activeMapControllOverlay = null">
            <img src="/assets/close.svg">
          </div>
          <div class="support-this-project">{{ 'labels.supportThisProject' | translate }}</div>
            <div class="basic-information">
              <svg width="100%" height="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                <path d="M32,0C49.661,0 64,14.339 64,32C64,49.661 49.661,64 32,64C14.339,64 0,49.661 0,32C0,14.339 14.339,0 32,0ZM34.82,43.111C34.82,44.111 34.84,44.951 34.88,45.631C34.92,46.311 35.06,46.871 35.3,47.311C35.54,47.751 35.91,48.091 36.41,48.331C36.91,48.571 37.62,48.751 38.54,48.871C38.78,48.911 38.96,49.021 39.08,49.201C39.2,49.381 39.26,49.591 39.26,49.831C39.26,50.031 39.2,50.221 39.08,50.401C38.96,50.581 38.78,50.671 38.54,50.671C37.82,50.671 36.88,50.601 35.72,50.461C34.56,50.321 33.38,50.251 32.18,50.251C30.9,50.251 29.65,50.321 28.43,50.461C27.21,50.601 26.04,50.671 24.92,50.671C24.72,50.671 24.56,50.581 24.44,50.401C24.32,50.221 24.26,50.031 24.26,49.831C24.26,49.591 24.32,49.381 24.44,49.201C24.56,49.021 24.74,48.911 24.98,48.871C26.02,48.751 26.83,48.571 27.41,48.331C27.99,48.091 28.41,47.761 28.67,47.341C28.93,46.921 29.08,46.401 29.12,45.781C29.16,45.161 29.18,44.391 29.18,43.471L29.18,33.811C29.18,32.091 29.14,30.711 29.06,29.671C28.98,28.631 28.8,27.841 28.52,27.301C28.24,26.761 27.82,26.391 27.26,26.191C26.7,25.991 25.94,25.831 24.98,25.711C24.74,25.711 24.56,25.611 24.44,25.411C24.32,25.211 24.26,25.011 24.26,24.811C24.26,24.611 24.32,24.411 24.44,24.211C24.56,24.011 24.74,23.911 24.98,23.911C25.38,23.871 25.87,23.831 26.45,23.791C27.03,23.751 27.64,23.701 28.28,23.641C28.92,23.581 29.57,23.501 30.23,23.401C30.89,23.301 31.48,23.191 32,23.071C32.44,22.991 32.79,22.901 33.05,22.801C33.31,22.701 33.52,22.651 33.68,22.651C34.16,22.651 34.47,22.791 34.61,23.071C34.75,23.351 34.82,23.611 34.82,23.851L34.82,43.111ZM31.64,16.531C30.64,16.531 29.78,16.221 29.06,15.601C28.34,14.981 27.98,14.211 27.98,13.291C27.98,12.371 28.32,11.591 29,10.951C29.68,10.311 30.52,9.991 31.52,9.991C32.48,9.991 33.27,10.321 33.89,10.981C34.51,11.641 34.82,12.431 34.82,13.351C34.82,14.231 34.52,14.981 33.92,15.601C33.32,16.221 32.56,16.531 31.64,16.531Z" style="fill:rgb(55,58,57);"/>
              </svg>
              <div class="support-us-text" [innerHTML]="'supportUs.text' | translate">
            </div>
            </div>
            <ng-template [ngTemplateOutlet]="donationOptions"></ng-template>
            <button class="button" [routerLink]="['/page/support-us']" [queryParamsHandling]="'preserve'" (click)="activeMapControllOverlay=null;apiService.setCurrentDisplay('page')">{{ 'labels.furtherInformation' | translate }}</button>
        </div>
      </div>

      <div class="zoom-to-wrapper" [ngClass]="{ hidden: activeMapControllOverlay != 'zoom-to-position' }">
        <form [formGroup]="locationForm">
          <input type="text"  class="form-control" id="google-maps-find-address-autocomplete" placeholder="{{ 'labels.place' | translate }}" formControlName="place" name="place">
          <div class="button" (click)="apiService.setMapToUserLocation()"><img src="/assets/zoom_to.svg" class="search-app-search-icon button-icon"></div>
          
        </form>
        <span class="search-text">
        </span>
      </div>

      <div class="map-options aggregations" [ngClass]="{ hidden: activeMapControllOverlay != 'map-aggregations' }">
        <div class="displayed-aggregation-wrapper">
          <label class="displayed-aggregation residence" id="mapAggregations-residence-label" [ngClass]="{ active: apiService.mapAggregations.includes('residence')}" (click)="apiService.toggleMapAggregation('residence')">
            <svg viewBox="0 0 40 40">
              <circle cx="20" cy="20" r="8" stroke="#000"></circle>
            </svg>
            <span>
              {{ 'labels.residence' | translate }}
            </span>
          </label>
          <label class="displayed-aggregation emigration" id="mapAggregations-emigration-label" [ngClass]="{active: apiService.mapAggregations.includes('emigration')}" (click)="apiService.toggleMapAggregation('emigration')">
            <svg viewBox="0 0 40 40">
              <circle cx="20" cy="20" r="8" stroke="#53A800"></circle>
            </svg>
            <span>
              {{ 'labels.emigration' | translate }}
            </span>
          </label>
          <label class="displayed-aggregation deportation" id="mapAggregations-deportation-label"  [ngClass]="{active: apiService.mapAggregations.includes('deportation')}" (click)="apiService.toggleMapAggregation('deportation')">
            <svg viewBox="0 0 40 40">
              <circle cx="20" cy="20" r="8" stroke="#ad0707"></circle>
            </svg>
            <span>
              {{ 'labels.deportation' | translate }}
            </span>
          </label>
        </div>
        <div class="map-aggregations-mtl-description" [innerHTML]="'labels.aggregationsDescription' | translate">
        </div>
      </div>
      <div class="map-options timeline" [ngClass]="{ hidden: activeMapControllOverlay != 'map-timeline' }">
        <form [formGroup]="mapControlForm">
          <div class="timeline-wrapper">
            <div class="timeline-wrapper-border" [ngClass]="{ active: mapControlForm.value.map_date != null }">
              <div class="map-date-selector-wrapper mtl-scrollbar">
                <div class="timeline">
                  <!-- <svg class="timeline-background" viewBox="0 0 100 10">
                    <line x1="0" y1="5" x2="100" y2="5"></line>
                  </svg> -->
                  <div class="date" *ngFor="let year of yearsRange">
                    <input type="radio" [id]="'map-year-' + year" [formControlName]="'map_date'" [value]="year">
                    <label [for]="'map-year-' + year" [id]="'map-year-' + year + '-label'">
                      <svg class="timeline-background" viewBox="0 0 10 10">
                        <line x1="5" y1="0" x2="5" y2="10"></line>
                      </svg>
                      <span>
                        {{ year }}
                      </span>
                    </label>
                  </div>
                  <div class="dates">
                  </div>
                </div>
              </div>
            </div>
            <div class="map-date-selector-mtl-default" [ngClass]="{disabled: !mapControlForm.value.map_date}">
              <input type="radio" [id]="'map-year-null'" [formControlName]="'map_date'" [value]="null">
              <label for="map-year-null">
                <img src="/assets/undo.svg" class="timeline-selection-undo button-icon">
              </label>
            </div>
          </div>
          <div class="map-date-mtl-description" [innerHTML]="'labels.timelineExplanationDefaultView' | translate"></div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <div class="footer-left-column">
    <span class="mtl" (click)="apiService.setCurrentDisplay('landing-page')" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">Mapping the Lives</span>
    <span (click)="apiService.setCurrentDisplay('landing-page')" [routerLink]="[ '/' ]" [queryParamsHandling]="'preserve'">{{ 'labels.ACentralMemorialForThePersecuted' | translate }}</span>
  </div>
  <div class="footer-right-column">
    <span>{{ 'labels.aProjectBy' | translate }} <a href="{{ 'tracingThePast.url' | translate }}" target=”_blank”>Tracing the Past e.V.</a> © 2019 - {{currentYear}}</span>
  </div>
</div>


<ng-template #personLink let-person="person">

  <div *ngIf="i==0" class="grid-full-width search-result-list-letter">
    {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
  </div>
  <div *ngIf="i>0 && getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() != getValueToDisplay(searchByQueryResults[i-1].fullname_display_list).charAt(0).toUpperCase()" class="grid-full-width search-result-list-letter">
      {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
  </div>
  <div class="person-list-item" [routerLink]="['/bio/', person.reference ]" [queryParamsHandling]="'preserve'" (click)="apiService.setCurrentDisplay('person')">
    <span class="person-list-name">{{ getValueToDisplay(person?.fullname_display_list) }}</span>
    <div class="person-dates-wrapper">
      <span class="hidden-in-tabular person-list-birth-sign">{{ (person?.birth?.hasOwnProperty('fuzzy_date_mean') && person.birth.fuzzy_date_mean != '') ? '*' : '' }}</span>
      <span class="person-list-birth-date">{{ getValueToDisplay(person?.birth?.fuzzy_date_display) }}</span>
      <span class="person-list-place-bd person-list-birth-place">{{ getValueToDisplay(person?.birth?.short_stringified_name) }}</span>
      <span class="hidden-in-tabular person-list-death-sign">{{ (person.death.hasOwnProperty('fuzzy_date_mean') && person.death.fuzzy_date_mean != '') ? '†' : '' }}</span>
      <span class="person-list-death-date">{{ getValueToDisplay(person?.death?.fuzzy_date_display) }}</span>
      <span class="person-list-place-bd person-list-death-place">{{ getValueToDisplay(person?.death?.short_stringified_name ) }}</span>
    </div>
    <span class="person-list-residence-address">{{ getResidenceValueToDisplay(person) }}</span>
  </div>
</ng-template>

<ng-template #personRow let-person="person">
  <tr class="person-list-item" [routerLink]="['/bio/', person.reference ]" [queryParamsHandling]="'preserve'" (click)="apiService.setCurrentDisplay('person')">
    <td class="person-list-name">{{ getValueToDisplay(person?.fullname_display_list) }}</td>
    <div class="person-dates-wrapper">
      <td class="hidden-in-tabular person-list-birth-sign">{{ (person.birth.hasOwnProperty('fuzzy_date_mean') && person.birth.fuzzy_date_mean != '') ? '*' : '' }}</td>
      <td class="person-list-birth-date">{{ getValueToDisplay(person?.birth?.fuzzy_date_display) }}</td>
      <td class="person-list-place-bd person-list-birth-place">{{ getValueToDisplay(person?.birth?.short_stringified_name) }}</td>
      <td class="hidden-in-tabular person-list-death-sign">{{ (person.death.hasOwnProperty('fuzzy_date_mean') && person.death.fuzzy_date_mean != '') ? '†' : '' }}</td>
      <td class="person-list-death-date">{{ getValueToDisplay(person?.death?.fuzzy_date_display) }}</td>
      <td class="person-list-place-bd person-list-death-place">{{ getValueToDisplay(person?.death?.short_stringified_name ) }}</td>
    </div>
    <td class="person-list-residence-address">{{ getResidenceValueToDisplay(person) }}</td>
  </tr>
</ng-template>