<div class="google-map-wrapper" [ngClass]="{'bio-page-map': !mainMap, 'static-bg': !apiService.consentGivenGoogleMaps_}">
    <div class="mtl-map-overlay-wrapper consent-form-wrapper" *ngIf="!apiService.consentGivenGoogleMaps_">
      <div class="mtl-horizontal-scroll-fade-overlay">
        <div class="scroll-wrapper">
          <span class="consent-form-title">{{ 'gdprConsent.title' | translate }}</span>
          <div class="consent-form-google-maps-information-wrapper">
            <span class="consent-form-text">{{ 'gdprConsent.text' | translate }}</span>
            <span class="consent-form-further-information">{{ 'gdprConsent.furtherInformationText' | translate }}</span>
            <a target="_blank" href="{{ 'gdprConsent.furtherInformationLink' | translate }}">{{ 'gdprConsent.furtherInformationLink' | translate }}</a>
            <span>{{ 'gdprConsent.ttpGDPRInformationText' | translate }} <a [routerLink]="['page/imprint']" [queryParamsHandling]="'preserve'" (click)="apiService.setCurrentDisplay('page')">{{ 'labels.imprintPage' | translate }}</a></span>
          </div>
          <div class="remember-consent-wrapper">
            <label class="toggle-switch">
              <input id="rememberConsent" type="checkbox" [checked]="rememberConsent" (change)="rememberConsent=!rememberConsent"/>
              <span class="slider round"></span>
            </label>
            <label for="rememberConsent">
              {{ 'gdprConsent.rememberConsent' | translate }}
            </label>
          </div>
          <span class="consent-form-revoke-consent-information">{{ 'gdprConsent.revokeConsentInformation' | translate }}</span>
          <button (click)="apiService.setConsentGiven(rememberConsent);">
            <span>{{ 'gdprConsent.acceptRequiredService' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <app-map *ngIf="(apiService.googleMapsApiLoaded|async) == true" [mainMap]="mainMap" [events]="events"></app-map>
</div>