import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    HostListener,
    Injector,
    Input,
    ViewContainerRef
  } from '@angular/core';
import {HighlightComponent} from "./highlight.component";

@Directive({
  selector: '[highlight]'
})
export class HighlightDirective {

  @Input() highlight = null;

  private componentRef: ComponentRef<any> = null;
  private showTimeout?: number;
  private hideTimeout?: number;
  private touchTimeout?: number;
  @Input() showDelay = 0;
  @Input() hideDelay = 0;

  constructor(
	private elementRef: ElementRef,
	private appRef: ApplicationRef, 
	private componentFactoryResolver: ComponentFactoryResolver,
	private injector: Injector) {
  }

  @HostListener('mouseenter')
    onMouseEnter(): void {
      this.initializeHighlight();
    }
  
    @HostListener('mouseleave')
    onMouseLeave(): void {
      this.setHideHighlightTimeout();
    }
  
    @HostListener('touchstart', ['$event'])
    onTouchStart($event: TouchEvent): void {
      $event.preventDefault();
      window.clearTimeout(this.touchTimeout);
      this.touchTimeout = window.setTimeout(this.initializeHighlight.bind(this), 500);
    }
  
    @HostListener('touchend')
    onTouchEnd(): void {
      window.clearTimeout(this.touchTimeout);
      this.setHideHighlightTimeout();
    }
  
    private initializeHighlight() {
        window.clearInterval(this.hideDelay);
        this.showTimeout = window.setTimeout(this.showHighlight.bind(this), this.showDelay);
    }
  
    private showHighlight() {
        let list = this.highlight;
        if ( !Array.isArray(this.highlight) ) {
            if ( typeof this.highlight != "string" ) { return; }
            list = [this.highlight];
        }
        
        list.forEach(className => {
            var elements:any = document.getElementsByClassName(className);
            for (let i=0; i<elements.length; i++) {
                if ( elements[i].classList.contains('map-marker') ) {
                    elements[i].parentElement.parentElement.classList.add('map-marker-highlight');
                }
                elements[i].classList.add("highlight");
            };
        });
    }
  
    private setHideHighlightTimeout() {
      this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
    }
  
    ngOnDestroy(): void {
      this.destroy();
    }
  
    destroy(): void {
        window.clearInterval(this.showTimeout);
        window.clearInterval(this.hideDelay);
      
        let list = this.highlight;
        if ( !Array.isArray(this.highlight) ) {
            if ( typeof this.highlight != "string" ) { return; }
            list = [this.highlight];
        }
        
        list.forEach(className => {
            var elements:any = document.getElementsByClassName(className);
            for (let i=0; i<elements.length; i++) {
                if ( elements[i].classList.contains('map-marker') ) {
                    elements[i].parentElement.parentElement.classList.remove('map-marker-highlight');
                }
                elements[i].classList.remove("highlight");
            };
        });
    }

}