
<google-map
  #map="googleMap"
  [ngClass]="{'bio-page-map': !mainMap}"
  [center]="center"
  [zoom]="zoom"
  [options]="mapOptions"
  (idle)="updateMap();"
  [height]="'100%'"
  [width]="'100%'"
  [mapId]="GOOGLE_MAPS_MAP_ID">
    <map-heatmap-layer
      [data]="residenceHeatmapData"
      [options]="residenceHeatmapOptions">
    </map-heatmap-layer>
    <map-heatmap-layer
      [data]="emigrationHeatmapData"
      [options]="emigrationHeatmapOptions">
    </map-heatmap-layer>
    <map-heatmap-layer
      [data]="deportationHeatmapData"
      [options]="deportationHeatmapOptions">
    </map-heatmap-layer>
    <map-advanced-marker
      #markerElem
      *ngFor="let marker of markers"
      [position]="marker.position"
      [label]="marker.label"
      [optimized]="false"
      [title]="marker.title"
      [icon]="marker.icon"
      [content]="marker.content"
      [type]="marker.type"
      (mapClick)="markerClickEvent(marker.position, marker.zoom, marker.type, marker.eventType, marker.reference)"
      [options]="marker.options">
    </map-advanced-marker>
    <div class="map-overlay-wrapper map-debug-wrapper" *ngIf="applicationState.debugMode">
      <div class="flex-row">
        <span>
          Min. Zoomlevel: {{ 2 }}
        </span>
        <span>
          Zoomlevel: {{ mapCurrentZoom }}
        </span>
        <span>
          Max. Zoomlevel: {{ 20 }}
        </span>
      </div>
      <div class="flex-row">
        <span>
          Map Bounds: {{ getMapBounds()|json }}
        </span>
      </div>
      <div class="flex-row">
        <span (click)="updateOnMapChange = !updateOnMapChange">
          Update on map change: {{ updateOnMapChange }}
        </span>
      </div>
    </div>
</google-map>
